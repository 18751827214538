import { IsBoolean, IsEmail, IsMobilePhone, IsNumber, IsOptional, IsPhoneNumber, IsString } from 'class-validator';

export class ContactPersonDto {
  @IsNumber()
  id: number;

  @IsString()
  firstName: string;

  @IsString()
  lastName: string;

  @IsString()
  salutation: string;

  @IsString()
  @IsEmail()
  email: string;

  @IsString()
  @IsPhoneNumber()
  phoneNumber: string;

  @IsString()
  @IsMobilePhone()
  mobileNumber: string;

  @IsNumber()
  addressId: number;

  @IsBoolean()
  @IsOptional()
  favorite?: boolean;

  constructor(
    id: number,
    firstName: string,
    lastName: string,
    salutation: string,
    email: string,
    phoneNumber: string,
    mobileNumber: string,
    addressId: number,
    favorite?: boolean,
  ) {
    this.id = id;
    this.firstName = firstName;
    this.lastName = lastName;
    this.salutation = salutation;
    this.email = email;
    this.phoneNumber = phoneNumber;
    this.mobileNumber = mobileNumber;
    this.addressId = addressId;
    this.favorite = favorite;
  }
}
