import { ActionCreator, Store } from '@ngrx/store';
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
  AvailableCol,
  AvailableField,
  MetaActionsGroup,
  MetaDataProperty,
  selectTableDefaultByName,
  selectUserTableSettingByName,
  UserActions,
} from '@rundp/api';
import { TypedAction } from '@ngrx/store/src/models';
import { Actions, concatLatestFrom, ofType } from '@ngrx/effects';
import { skipWhile } from 'rxjs';
import { map } from 'rxjs/operators';
import { inject } from '@angular/core';

/**
 * Global function that can be applied in other effect-classes.
 * Loads the metadata for a specific table (e.g. available and selected columns)
 * from the User- and Metadata-Object loaded upon startup
 * @param tableName The current table that should be searched for
 * @param action The action that should be returned with the found data
 */
export const effectToSetDefaultsToTableStores = (
  tableName: MetaDataProperty,
  action: ActionCreator<
    string,
    (props: { selectedItems: string[]; tableMetadata: AvailableCol[]; searchMetadata: AvailableField[] }) => {
      selectedItems: string[];
      tableMetadata: AvailableCol[];
      searchMetadata: AvailableField[];
    } & TypedAction<string>
  >,
) => {
  const store = inject(Store);
  const actions$ = inject(Actions);

  return actions$.pipe(
    ofType(UserActions.loadUserSuccess, MetaActionsGroup.loadMetaSuccess), // both actions have to be dispatched as the skipWhile blocks the execution otherwise
    concatLatestFrom(() => [store.select(selectUserTableSettingByName(tableName)), store.select(selectTableDefaultByName(tableName))]),
    skipWhile(([, tableSettings, tableDefault]) => tableSettings === undefined || tableDefault === undefined),
    map(([, usrTableSettings, metaTableDefault]) => {
      const tableCols =
        usrTableSettings!.tableTemplates.find((value) => value.id === usrTableSettings!.selectedTableTemplateID)?.selectedCols ||
        metaTableDefault!.defaultCols;
      const selectedItems: string[] = [];

      for (let i = 0, max = tableCols.length; i < max; i++) {
        const tableColumnName = tableCols[i];
        const col = metaTableDefault!.availableCols.find((col) => col.name === tableColumnName)?.name;

        if (col) {
          selectedItems.push(col);
        }
      }

      return action({
        selectedItems,
        tableMetadata: metaTableDefault!.availableCols,
        searchMetadata: metaTableDefault!.availableSearchFields,
      });
    }),
  );
};
