import { createFeature, createReducer, on } from '@ngrx/store';
import { BusinessTypeActions, BusinessTypesSelectedActions, CalculationActions } from './calculation.actions';
import { CalculationDto } from '../../types/calculation/calculation.dto';
import { NewCalculation } from '../../types/calculation/new-calculation';
import { pageFailureReducer, pageReducer } from '../common/reducer';
import { AvailableCol, AvailableField, TableRow } from '../../types';

/**
 * The calculation feature key used in the store
 */
export const calculationFeatureKey = 'calculation';

/**
 * The calculation state
 * @interface CalculationState
 * @property {CalculationDto[]} calculations - the calculations
 *
 */
export interface CalculationState {
  calculations: CalculationDto[];
  currentPageIds: null;
  totalCount: 0;

  selectedVariantDetails: CalculationDto;
  newCalculation: NewCalculation;

  tableMetadata: AvailableCol[] | null;
  tableData: TableRow[] | null;
  searchMetadata: AvailableField[] | null;
}

/**
 * The initial state of the calculation state
 * @type CalculationState
 * @property {CalculationDto[]} calculations - the calculations
 */
export const calcInitState: CalculationState = {
  calculations: [],
  currentPageIds: null,
  totalCount: 0,

  selectedVariantDetails: {
    id: 0,
    addressId: 0,
    calculationVariantList: [],
    createdAt: 0,
  },

  newCalculation: {
    businessTypes: [],
    selectedBusinessTypes: [],
  },

  tableMetadata: null,
  tableData: null,
  searchMetadata: null,
};

/**
 * The calculation reducer
 * @function calculationReducer
 * @param {CalculationState} state - the state
 * @param {CalculationActions} action - the action
 * @returns {CalculationState} - the new state
 */
export const calculationReducer = createReducer(
  calcInitState,
  on(CalculationActions.getAllCalculation, (state: CalculationState): CalculationState => ({ ...state })),

  /*on(CalculationActions.getAllCalculationSuccess, (state: CalculationState, { data }): CalculationState => {
    return { ...state, calculations: data };
  }),*/
  // on(CalculationActions.getAllCalculationSuccess, (state, action) => pageReducer(state, action, 'calculations', 'currentPageIds')),
  on(CalculationActions.getAllCalculationSuccess, (state, action) => pageReducer(state, action, 'tableData', 'currentPageIds')),
  on(CalculationActions.getAllCalculationFailure, (state) => pageFailureReducer(state, 'currentPageIds')),

  /*----------------------------------------------------------------------------------------------------*/
  on(BusinessTypeActions.getBusinessTypesSuccess, (state, { businessTypes }): CalculationState => {
    return { ...state, newCalculation: { ...state.newCalculation, businessTypes } };
  }),

  on(BusinessTypeActions.getBusinessTypesFailure, (state): CalculationState => ({ ...state })),

  /*----------------------------------------------------------------------------------------------------*/
  on(BusinessTypesSelectedActions.getBusinessTypesSelected, (state, { selectedBusinessTypes }): CalculationState => {
    return { ...state, newCalculation: { ...state.newCalculation, selectedBusinessTypes } };
  }),

  // todo review machen die Aktionen (diese und darunter nicht genach das gleiche? Warum gibt es zwei aktionen?
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  on(BusinessTypesSelectedActions.updateSelected, (state, { oldValue, newValue }): CalculationState => {
    // console.log('calculation.reducers.updateSelected. oldValue: ', oldValue, ' newValue: ', newValue);

    return { ...state, newCalculation: { ...state.newCalculation, selectedBusinessTypes: newValue } };
  }),
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  on(BusinessTypesSelectedActions.updateSelectedOnlyModel, (state, { oldValue, newValue }): CalculationState => {
    return { ...state, newCalculation: { ...state.newCalculation, selectedBusinessTypes: newValue } };
  }),

  on(CalculationActions.getCalculationByVariantIdSuccess, (state, { data }): CalculationState => {
    console.log('calculation.reducers. CalculationActions.getCalculationByVariantIdSuccess', data);

    return { ...state, selectedVariantDetails: data };
  }),
);

/**
 * The calculation feature
 * @type Feature
 */
export const calculationFeature = createFeature({
  name: calculationFeatureKey,
  reducer: calculationReducer,
});

/*----------------------------------------------------------------------------------------------------*/
