import {
  IsArray,
  IsEmail,
  IsMobilePhone,
  IsNumber,
  IsOptional,
  IsPhoneNumber,
  IsString,
  IsUrl,
  MaxLength,
  MinLength,
} from 'class-validator';

export class AddressDto {
  @IsNumber()
  id: number;

  @IsString()
  name1: string;

  @IsString()
  @IsOptional()
  name2?: string;

  @IsString()
  street: string;

  @IsString()
  city: string;

  @IsString()
  @MaxLength(5)
  @MinLength(5)
  postCode: string;

  @IsString()
  country: string;

  @IsString()
  @IsEmail()
  email: string;

  @IsString()
  @IsPhoneNumber()
  phoneNumber: string;

  @IsString()
  @IsMobilePhone()
  mobileNumber: string;

  @IsNumber()
  favoriteBankAccountId: number;

  @IsArray()
  bankAccountIds: number[];

  @IsNumber()
  favoriteContactPersonId: number;

  @IsArray()
  contactPersonIds: number[];

  @IsArray()
  addressTags: { id: number; tagName: string }[];

  @IsString()
  companyType: string;

  @IsString()
  addressType: string;

  @IsString()
  crefoNumber: string;

  @IsString()
  companyRegistrationNumber: string;

  @IsString()
  @IsUrl()
  website: string;

  @IsString()
  vatId: string;

  constructor(
    id: number,
    name1: string,
    street: string,
    postCode: string,
    city: string,
    country: string,
    email: string,
    phoneNumber: string,
    mobileNumber: string,
    favoriteBankAccountId: number,
    bankAccountIds: number[],
    favoriteContactPersonId: number,
    contactPersonIds: number[],
    addressTags: { id: number; tagName: string }[],
    companyType: string,
    addressType: string,
    crefoNumber: string,
    companyRegistrationNumber: string,
    website: string,
    vatId: string,
    name2?: string,
  ) {
    this.id = id;
    this.name1 = name1;
    this.name2 = name2;
    this.street = street;
    this.postCode = postCode;
    this.city = city;
    this.country = country;
    this.email = email;
    this.phoneNumber = phoneNumber;
    this.mobileNumber = mobileNumber;
    this.favoriteBankAccountId = favoriteBankAccountId;
    this.bankAccountIds = bankAccountIds;
    this.favoriteContactPersonId = favoriteContactPersonId;
    this.contactPersonIds = contactPersonIds;
    this.addressTags = addressTags;
    this.companyType = companyType;
    this.addressType = addressType;
    this.crefoNumber = crefoNumber;
    this.companyRegistrationNumber = companyRegistrationNumber;
    this.website = website;
    this.vatId = vatId;
  }
}
