import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BankConnectionDto } from '../../types/bank-connection/bank-connection.dto';
import { environment } from '@rundp/environment';
import { Observable } from 'rxjs';
import { ServerResponse } from '../../../index';

/**
 * @class BankConnectionService
 * @classdesc Represents a service for managing bank connections.
 * @param {HttpClient} _http - The HttpClient service for making HTTP requests.
 */
@Injectable({
  providedIn: 'root',
})
export class BankConnectionService {
  private _http = inject(HttpClient);

  /**
   * Gets the bank connection for the given ID.
   *
   * @param {number} id - The ID of the bank connection.
   * @return {Observable<BankConnectionDto>} - The bank connection details.
   */
  public getBankConnection(id: number) {
    return this._http.get<BankConnectionDto>(environment.apiURL + environment.apiEndpoints.bankAccount + '/' + id);
  }

  /**
   * Retrieves the bank connections by address ID.
   *
   * @param {string | number} addressId - The ID of the address.
   *
   * @return {Observable<BankConnectionDto[]>} - An observable that emits the bank connections.
   */
  public getBankConnectionsByAddressId(addressId: string | number) {
    return this._http.get<BankConnectionDto[]>(environment.apiURL + environment.apiEndpoints.bankAccount + '/all/' + addressId);
  }

  /**
   * Creates a bank connection.
   *
   * @param {Omit<BankConnectionDto, 'id'>} bankConnection - The bank connection to create.
   * @return {Observable<ServerResponse<BankConnectionDto>>} - A promise that resolves to the newly created bank connection.
   */
  public createBankConnection(bankConnection: Omit<BankConnectionDto, 'id'>) {
    return this._http.post<ServerResponse<BankConnectionDto>>(environment.apiURL + environment.apiEndpoints.bankAccount, bankConnection);
  }

  /**
   * Updates a bank connection.
   *
   * @param {BankConnectionDto} bankConnection - The bank connection object to update.
   * @return {Observable<BankConnectionDto>} - The updated bank connection object.
   */
  public updateBankConnection(bankConnection: BankConnectionDto): Observable<BankConnectionDto> {
    return this._http.put<BankConnectionDto>(environment.apiURL + environment.apiEndpoints.bankAccount, bankConnection);
  }

  /**
   * Deletes a bank connection.
   *
   * @param {number} id - The ID of the bank connection to be deleted.
   * @return {Observable<BankConnectionDto>} - An Observable object containing the deleted bank connection data.
   */
  public deleteBankConnection(id: number): Observable<BankConnectionDto> {
    return this._http.delete<BankConnectionDto>(environment.apiURL + environment.apiEndpoints.bankAccount + '/' + id);
  }

  /**
   * Promotes a bank connection to the favorited one for the corresponding address.
   *
   * @param {number} id - The ID of the bank connection to be promoted as favorite.
   * @return {Observable<ServerResponse<BankConnectionDto>>} - An Observable object containing the favorite bank connection data.
   */
  public setFavoriteBankConnection(id: number) {
    return this._http.put<ServerResponse<BankConnectionDto>>(
      environment.apiURL + environment.apiEndpoints.bankAccount + '/favorite/' + id,
      {},
    );
  }
}
