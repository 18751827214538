import { inject, Injectable, signal, WritableSignal } from '@angular/core';
import { KeycloakEventType, KeycloakService } from 'keycloak-angular';
import { filter } from 'rxjs';
import { Store } from '@ngrx/store';
import { UserActions } from '../+state';
import { environment } from '@rundp/environment';

@Injectable({ providedIn: 'root' })
export class LoginService {
  private initRequested = false;
  readonly keycloak = inject(KeycloakService);
  readonly initKeycloakEvent: WritableSignal<boolean> = signal(false);
  readonly authKeycloakEvent: WritableSignal<boolean> = signal(false);

  private readonly store = inject(Store);

  /**
   * Initializes Keycloak and returns a function that can be used to initialize Keycloak.
   *
   * @returns {function} A function that initializes Keycloak.
   * @throws {Error} If Keycloak initialization has already been requested.
   */
  initKeycloak() {
    if (!this.initRequested) {
      this.registerEvents();

      this.initRequested = true;

      return () =>
        this.keycloak.init({
          config: {
            url: environment.keycloakURL,
            realm: 'RundP',
            clientId: 'frontend',
          },
          initOptions: {
            onLoad: 'check-sso',
            silentCheckSsoRedirectUri: window.location.origin + '/assets/silent-check-sso.html',
          },
          loadUserProfileAtStartUp: true,
          bearerExcludedUrls: ['https://openiban.com'],
        });
    } else {
      throw new Error('Keycloak-Initialisierung bereits angefordert!');
    }
  }

  private registerEvents() {
    this.keycloak.keycloakEvents$
      .pipe(filter((event) => event.type === KeycloakEventType.OnReady || event.type === KeycloakEventType.OnAuthSuccess))
      .subscribe((event) => {
        if (event.type === KeycloakEventType.OnAuthSuccess) {
          this.authKeycloakEvent.set(true);
          this.store.dispatch(UserActions.loadUser());
        } else {
          this.initKeycloakEvent.set(true);
        }
      });
  }
}
