import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as fromUser from './user.reducers';
import {GenericTableSetting, MetaDataProperty, NavbarItem, TableSetting, UserInfo} from '../../types';

/**
 * Retrieves the user state from the user feature store.
 *
 * @function selectUserState
 * @returns {Function} - A selector function that returns the user state.
 *
 * @example
 * const userState = selectUserState();
 * console.log(userState); // { isLoggedIn: false, username: '', email: '' }
 */
export const selectUserState = createFeatureSelector<fromUser.UserState>(fromUser.userFeatureKey);

/**
 * Selects the user state from the application state.
 *
 * @function
 * @name selectUser
 * @param {Object} state - The application state.
 * @returns {fromUser.UserState} The user state.
 */
export const selectUser = createSelector(selectUserState, (userState) => userState);

/**
 * Retrieve the first name of the selected user.
 *
 * @param {Function} selectUser - The selector function that retrieves the user object.
 *
 * @returns {string} - The first name of the selected user.
 */
export const selectUserFirstname = createSelector(selectUser, (user) => {
  return user.firstname;
});

/**
 * Returns the last name of the selected user.
 *
 * @param {Function} selectUser - The selector function to select the user.
 * @returns {string} - The last name of the selected user.
 */
export const selectUserLastname = createSelector(selectUser, (user) => {
  return user.lastname;
});

/**
 * A selector function that returns the full name of a user.
 *
 * @param {Function} selectUser - The selector function that retrieves the user object.
 * @param {Function} user - The user object.
 * @return {UserInfo} The full name of the user.
 */
export const selectUserFullname = createSelector(selectUser, (user) => {
  const retVal: UserInfo = {
    firstName: user.firstname,
    lastName: user.lastname,
  };

  return retVal;
});

/**
 * Returns a memoized selector that selects the role of a user.
 *
 * @param {Function} selectUser - A selector function that selects the user object.
 * @returns {string} - A memoized selector that selects the role of the user.
 */
export const selectUserRole = createSelector(selectUser, (user) => {
  return user.role;
});

/**
 * Returns a selector that efficiently selects the navbar items from the user state.
 *
 * @param {Function} selectUser - A selector function that selects the user object from the state.
 * @returns {NavbarItem[]} A memoized selector function that selects the navbar items from the user state.
 */
export const selectUserNavbarItems = createSelector(selectUser, (user) => user.navbarItems);

/**
 * Selects a user navbar item by index.
 *
 * @param {number} id - The index of the navbar item.
 * @returns {NavbarItem|undefined} - A selector function that returns the user navbar item with the specified index.
 */
export const selectUserNavbarItemByInd = (id: number) =>
  createSelector<object, NavbarItem[], NavbarItem | undefined>(selectUserNavbarItems, (navbarItems) => navbarItems[id]);

/**
 * Selects a user navigation bar item by name.
 *
 * @param {string} name - The name of the navigation bar item.
 * @returns {NavbarItem | undefined} - The Reselect selector function.
 */
export const selectUserNavbarItemByName = (name: string) =>
  createSelector<object, NavbarItem[], NavbarItem | undefined>(selectUserNavbarItems, (navbarItems) =>
    navbarItems?.find((value) => value.displayName === name),
  );

/**
 * Returns a memoized selector for the table settings of the selected user.
 *
 * @name selectUserTableSettings
 * @function
 * @param {Function} selectUser - A selector function that returns the selected user.
 * @returns {TableSetting[]} - A memoized selector function that returns the table settings of the selected user.
 * // Returns the table settings of the selected user
 */
export const selectUserTableSettings = createSelector(selectUser, (user) => user.tableSettings);

/**
 * Returns a selector function that retrieves a specific user table setting by its ID.
 *
 * @param {number} id - The ID of the table setting to retrieve.
 * @returns {TableSetting|undefined} A selector function that accepts the input selector state and returns the matching table setting.
 */
export const selectUserTableSettingById = (id: number) =>
  createSelector<object, TableSetting[], TableSetting | undefined>(selectUserTableSettings, (tableSettings) => tableSettings[id]);

/**
 * Selects a user table setting by name.
 *
 * @param {MetaDataProperty} name - The name of the table setting to select.
 * @returns {TableSetting | undefined} - The selected table setting, or undefined if not found.
 */
export const selectUserTableSettingByName = (name: MetaDataProperty) =>
  createSelector<object, TableSetting[], TableSetting | undefined>(selectUserTableSettings, (tableSettings) =>
    tableSettings?.find((value) => value.name === name),
  );

/**
 * Creates a selector to retrieve a generic table setting from the user table settings based on the provided name.
 *
 * @param {MetaDataProperty} name - The name of the table setting to retrieve.
 * @returns {createSelector<object, TableSetting | undefined, GenericTableSetting | undefined>} - The created selector function.
 */
export const selectUserTableSettingAsGeneric = (name: MetaDataProperty) =>
  createSelector<object, TableSetting | undefined, GenericTableSetting | undefined>(selectUserTableSettingByName(name), (setting) => {
    if (!setting) {
      return undefined;
    }

    return {
      ...setting,
      templates:
        setting?.tableTemplates.map((tableTemplate) => {
          return {
            ...tableTemplate,
            selectedItems: tableTemplate.selectedCols,
          };
        }) ?? [],
      selectedTemplateID: setting?.selectedTableTemplateID ?? -1,
    };
  });

/**
 * Selects the names of user table settings.
 *
 * @param  {object} state - The state object containing user table settings.
 * @return {TableSetting[]} The array of table setting names.
 */
export const selectUserTableSettingNames = createSelector<object, TableSetting[], string[]>(selectUserTableSettings, (tableSettings) =>
  tableSettings.map((value) => value.name),
);

/**
 * A selector function that retrieves the footer of the selected user.
 *
 * @param {Function} selectUser - A selector function that retrieves the selected user.
 * @param {Object} user - The selected user.
 * @returns {Footer} - The footer of the selected user.
 */
export const selectUserFooter = createSelector(selectUser, (user) => {
  return user.footer;
});
