import {createActionGroup, emptyProps, props} from "@ngrx/store";
import {ErrorCodeDetails} from "../../error/error-codes";
import {ErrType} from "./errorInitState";

export const ErrorActions = createActionGroup({
    source: 'Error',
    events: {
        'Error Set': props<{ active: ErrorCodeDetails, errType: ErrType }>(),
        'Ignore Next Global Http Error Handling': props<{ active?: ErrorCodeDetails }>(),
        'Restart Next Global Http Error Handling': emptyProps(),
        'Error Reset': emptyProps(),
    }
});
