import {createFeatureSelector, createSelector} from '@ngrx/store';
import {ErrorState} from './errorInitState';

/**
 * Represents the feature key used to identify the error feature.
 *
 * @type {string}
 */
export const errorFeatureKey = 'error';

/**
 * Represents a variable that is used to select the error state from the Redux store.
 *
 * @type {function}
 * @param {string} errorFeatureKey - The key of the error feature in the Redux store.
 * @return {ErrorState} - The selected error state from the Redux store.
 */
export const selectErrorState = createFeatureSelector<ErrorState>(errorFeatureKey);

/**
 * Retrieves the active state from the error state.
 *
 * @param {function} selectErrorState - The selector function to retrieve the error state.
 * @param {object} state - The ErrorState object containing error related information.
 * @returns {boolean} - The active state from the error state.
 */
export const selectErrorActiveState = createSelector(selectErrorState, (state: ErrorState) => state.active);

/**
 * Indicates whether the next HTTP error should be ignored.
 *
 * @returns {boolean}
 */
export const selectIgnoreError = createSelector(selectErrorState, (state: ErrorState) => state.ignoreNextHttpErr === true);
