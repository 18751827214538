import { APP_INITIALIZER, importProvidersFrom, makeEnvironmentProviders } from '@angular/core';
import { KeycloakAngularModule } from 'keycloak-angular';
import { LoginService } from './login.service';

function initializeKeycloak(loginService: LoginService) {
  return loginService.initKeycloak();
}

export function getKeycloak() {
  return {
    provide: APP_INITIALIZER,
    useFactory: initializeKeycloak,
    multi: true,
    deps: [LoginService],
  };
}

export const provideKeycloak = (/*config?: {}*/) => {
  return makeEnvironmentProviders([importProvidersFrom(KeycloakAngularModule), getKeycloak()]);
};
