import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { CalculationDto } from '../../types/calculation/calculation.dto';
import { BusinessTypeDto, CalculationVariant } from '../../types';

/**
 *  The CalculationActions is a collection of actions that are used to manage the state of the calculation module.
 *  @type {CalculationActions}
 * */
export const CalculationActions = createActionGroup({
  source: 'Calculation',
  events: {
    /*
    /calculation/all // Method: GET. Description: Get list of all calculations
    /calculation // Method: PUT. Description: Update calculation variant
    /calculation // Method: POST. Description: Save calculation
    /calculation/{calculationVariantId} // Method: GET. Description: Get calculation by variant id
    /calculation/{calculationVariantId} // Method: DELETE. Description: Delete calculation variant by id
    */
    'Load Common Data': emptyProps(),

    'Get All Calculation': emptyProps(),
    'Get All Calculation Success': props<{ data: CalculationDto[]; paginationData: { totalCount: number } }>(),
    'Get All Calculation Failure': props<{ error: string }>(),

    'Update Calculation': props<{ data: CalculationVariant; isUpdate: boolean }>(),
    'Update Calculation Success': emptyProps(),
    'Update Calculation Failure': props<{ error: unknown }>(),

    'Save Calculation': props<{ data: CalculationVariant; isUpdate: boolean }>(),
    'Save Calculation Success': emptyProps(),
    'Save Calculation Failure': props<{ error: unknown }>(),

    'Get Calculation By Variant Id': props<{ calculationID: string; variantID: string }>(),
    'Get Calculation By Variant Id Success': props<{ data: CalculationDto }>(),
    'Get Calculation By Variant Id Failure': props<{ error: unknown }>(),

    'Set Calculation By Variant Id': emptyProps(),
    'Set Calculation By Variant Id Success': props<{ data: unknown[]; paginationData: { totalCount: number } }>(),
    'Set Calculation By Variant Id Failure': props<{ error: unknown }>(),

    'Delete Calculation By Variant Id': props<{ calculationVariantId: number }>(),
    'Delete Calculation By Variant Id Success': props<{ calculationVariantId: number }>(),
    'Delete Calculation By Variant Id Failure': props<{ error: unknown }>(),
  },
});

/**
 * BusinessTypeActions is a collection of actions that are used to manage the state of the business type module.
 * @type {BusinessTypeActions}
 */
export const BusinessTypeActions = createActionGroup({
  source: 'BusinessType',
  events: {
    'Get Business Types': emptyProps(),
    'Get Business Types Success': props<{ businessTypes: BusinessTypeDto[] }>(),
    'Get Business Types Failure': props<{ error: unknown }>(),
  },
});

/**
 * BusinessTypesSelectedActions is a collection of actions that are used to manage the state of the business types selected module.
 * @type {BusinessTypesSelectedActions}
 */
export const BusinessTypesSelectedActions = createActionGroup({
  source: 'BusinessTypesSelected',
  events: {
    'Get Business Types Selected': props<{ selectedBusinessTypes: string[] }>(),
    'Update Selected': props<{ oldValue: string[]; newValue: string[] }>(),
    'Update Selected Only Model': props<{ oldValue: string[]; newValue: string[] }>(),

    'Control Business Types': props<{ businessTypes: BusinessTypeDto[] }>(),
  },
});
