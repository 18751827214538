import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AddressDto, TableDefault, TableRow } from '../../types';
import { environment } from '@rundp/environment';
import { ServerResponse, ServerTableResponse } from '../../interceptors';

/**
 * Service that interacts with the Address API.
 */
@Injectable({
  providedIn: 'root',
})
export class AddressApiService {
  constructor(private readonly _http: HttpClient) {}

  /**
   * Retrieves the address information for the specified ID.
   *
   * @param {string} id - The unique identifier for the address.
   * @return {Observable<AddressDto>} - An observable that emits the address data.
   */
  public getAddress(id: string | number) {
    return this._http.get<ServerResponse<AddressDto>>(environment.apiURL + environment.apiEndpoints.address + '/' + id);
  }

  /**
   * Retrieves addresses based on the given parameters.
   *
   * @param {HttpParams} params - The parameters to be included in the request.
   * @return {Observable<AddressDto[]>} - An observable that emits the addresses.
   */
  public getAddresses(params: HttpParams) {
    return this._http.get<ServerTableResponse<AddressDto[]>>(`/api/addresses`, {
      observe: 'response',
      params,
    });
  }

  /**
   * Creates a new address.
   *
   * @param {Partial<AddressDto>} address - The address data.
   * @return {Observable<AddressDto>} The observable representing the created address.
   */
  public createAddress(address: Partial<AddressDto>) {
    return this._http.post<AddressDto>(environment.apiURL + environment.apiEndpoints.address, address);
  }

  /**
   * Updates the address in the database.
   *
   * @param {Partial<AddressDto>} address - The partial address object containing the updated values.
   * @return {Observable<AddressDto>} The updated address object as an Observable.
   */
  public updateAddress(address: Partial<AddressDto>) {
    return this._http.put<AddressDto>(environment.apiURL + environment.apiEndpoints.address, address);
  }

  /**
   * Deletes an address with the specified ID.
   *
   * @param {string} id - The ID of the address to delete.
   * @return {Promise<void>} - A promise that resolves with no value upon successful deletion.
   */
  public deleteAddress(id: string) {
    return this._http.delete<void>(environment.apiURL + environment.apiEndpoints.address + '/' + id);
  }

  /**
   * Retrieves all metadata for the address table from the server.
   *
   * @returns {Observable<{ tableDefaults: TableDefault[] }>} The observable that emits the table metadata.
   */
  public getTableMetadata() {
    return this._http.get<{ tableDefaults: TableDefault[] }>(environment.apiURL + environment.apiEndpoints.addressMetaData);
  }

  /**
   * Retrieves table data from the server.
   *
   * @param {HttpParams} params - The parameters for the HTTP request.
   * @returns {Observable<TableRow[]>} The observable that emits the table data.
   */
  public getTableData(params: HttpParams) {
    return this._http.get<ServerTableResponse<TableRow[]>>(environment.apiURL + environment.apiEndpoints.addressTableData, {
      params,
    });
  }
}
