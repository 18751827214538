import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';

/**
 * LoaderCounterStore represents a store for managing a counter.
 * It provides methods for incrementing and decrementing the counter value.
 *
 * @typedef {Object} LoaderCounterStore
 * @property {number} count - The current count value.
 * @property {function} increment - A method for incrementing the count value by 1.
 * @property {function} decrement - A method for decrementing the count value by 1.
 */
export const LoaderCounterStore = signalStore(
  { providedIn: 'root' },

  withState({ count: 0 }),

  withMethods(({ count, ...store }) => ({
    increment() {
      patchState(store, { count: count() + 1 });
    },
    decrement() {
      patchState(store, { count: Math.max(count() - 1, 0) });
    },
  })),
);
