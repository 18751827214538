import { createFeature, createReducer, on } from '@ngrx/store';
import { UserActions } from './user.actions';
import { Footer, NavbarItem, TableSetting } from '../../types';

export const userFeatureKey = 'user';

export interface UserState {
  /*user: UserDto;*/
  id: string;
  firstname: string;
  lastname: string;
  role: string;
  navbarItems: NavbarItem[];
  tableSettings: TableSetting[];
  footer: Footer;
}

export const initialUser: UserState = {
  // user: {
  id: '',
  firstname: '',
  lastname: '',
  role: '',
  navbarItems: [],
  tableSettings: [],
  footer: {} as Footer,
  // }
};

export const userReducer = createReducer(
  initialUser,
  on(UserActions.loadUser, (state): UserState => {
    return { ...state };
  }),
  on(UserActions.loadUserSuccess, (state, { data }): UserState => {
    return {
      ...state,
      ...data.user,
    };
  }),
  on(UserActions.loadUserFailure, (state): UserState => {
    return { ...state };
  }),

  on(UserActions.deleteTableTemplateSuccess, (state, action) => ({
    ...state,
    tableSettings: state.tableSettings.map((tableSetting: TableSetting) => {
      if (tableSetting.name !== action.tableName) {
        return tableSetting;
      }

      return {
        ...tableSetting,
        tableTemplates: tableSetting.tableTemplates.filter((template) => template.id !== action.templateId),
        selectedTableTemplateID: tableSetting.selectedTableTemplateID === action.templateId ? -1 : tableSetting.selectedTableTemplateID,
      };
    }),
  })),

  on(UserActions.updateSelectedTableTemplateSuccess, (state, action) => ({
    ...state,
    tableSettings: state.tableSettings.map((tableSetting: TableSetting) => {
      if (tableSetting.name !== action.tableName) {
        return tableSetting;
      }

      return {
        ...tableSetting,
        tableTemplates: tableSetting.tableTemplates.map((template) => {
          if (template.id === action.tableTemplate.id) {
            return action.tableTemplate;
          }

          return template;
        }),
        selectedTableTemplateID: action.tableTemplate.id,
      };
    }),
  })),

  on(UserActions.updateSelectedAndCreateTableTemplateSuccess, (state, action) => ({
    ...state,

    tableSettings: state.tableSettings.map((tableSetting: TableSetting) => {
      if (tableSetting.name !== action.tableName) {
        return tableSetting;
      }

      return {
        ...tableSetting,
        tableTemplates: [...tableSetting.tableTemplates, action.tableTemplate],
        selectedTableTemplateID: action.tableTemplate.id,
      };
    }),
  })),

  on(UserActions.updateSelectedTableTemplateIndexSuccess, (state, action) => ({
    ...state,
    tableSettings: state.tableSettings.map((tableSetting: TableSetting) => {
      if (tableSetting.name !== action.tableName) {
        return tableSetting;
      }

      return { ...tableSetting, selectedTableTemplateID: action.templateId };
    }),
  })),
);

export const userFeature = createFeature({
  name: userFeatureKey,
  reducer: userReducer,
});
