export enum ColumnAlignmentEnum {
  left = 'left',
  right = 'right',
  center = 'center',
}

export type ColumnAlignment = ColumnAlignmentEnum.left | ColumnAlignmentEnum.right | ColumnAlignmentEnum.center;

export type TableMetadata = {
  displayName: string;
  name: string;
  alignment: ColumnAlignment;
  isVisible: boolean;
  maxWidth?: string;
  minWidth?: string;
};

export type TableCell = {
  html: string;
  styles?: string;
};

export type TableRow = {
  checked?: boolean;
  actionsDisabled?: boolean;
  id: string;
  data: {
    [columnName: string]: TableCell;
  };
};

export type PagingData = {
  totalCount: number;
  pageIndex: number;
  pageSize: number;
};

/**
 * Represents a table action.
 */
export type TableAction = {
  /**
   * Name of the action.
   * Must be unique.
   */
  name: string;
  // label: string;
  labelLocalized: string;
  icon: string;
  isSingleRowAction?: boolean;
};

export type TableDataResponse = {
  totalCount: number;
  searchRequestDTO: {
    size: number;
    offset: number;
    sort: string | null;
    direction: string | null;
    query: string | null;
  };
  tableRows: TableRow[];
};
