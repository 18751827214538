import { HttpParams } from '@angular/common/http';
import { Filter } from '../../types/common/filter.type';
import { PageData, Sort } from '../../types/common/paginated-api-request.type';
import { AvailableFilter } from '../../types/meta';

export class PaginatedRequestParamsFactory<DTO> {
  private _params: HttpParams = new HttpParams();

  addFilter(filter?: Filter<DTO>) {
    if (!filter) {
      return this;
    }

    if (!filter.all) {
      Object.entries(filter).forEach(([key, value]) => {
        if (value !== undefined && value !== null && value.length > 0) {
          this._params = this._params.set(key, value.toString());
        }
      });

      return this;
    }

    this._params = this._params.set('_query', filter.all.toString());

    return this;
  }

  addSort(sort?: Sort<DTO>) {
    if (!sort) {
      return this;
    }

    this._params = this._params.set('_sort', sort.sortBy.toString());
    this._params = this._params.set('_order', sort.sortDirection);

    return this;
  }

  addPagination({ pageIndex, pageSize }: PageData) {
    this._params = this._params.set('_page', pageIndex.toString());
    this._params = this._params.set('_limit', pageSize.toString());

    return this;
  }

  addQuickFilters(quickFilters: AvailableFilter[]) {
    if (quickFilters.length > 0) {
      const filterValues = quickFilters.map((filter) => filter.id + '');
      const filterString = filterValues.reduce((previousValue, currentValue) => previousValue + ',' + currentValue);

      this._params = this._params.set('_filter', filterString);
    }

    return this;
  }

  build() {
    return this._params;
  }
}
