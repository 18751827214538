import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CalculationApiService } from '../../services/calculation/calculation-api.service';
import { ErrorActions } from '../error/error.actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { BusinessTypeActions, BusinessTypesSelectedActions, CalculationActions } from './calculation.actions';
import { CalculationDto } from '../../types/calculation/calculation.dto';
import { selectBusinessTypesAsArray, selectBusinessTypesSelected } from './calculation.selectors';
import { BusinessFields, BusinessTypeDto } from '../../types';

/**
 * CalculationEffects is a collection of effects that are used to manage the state of the calculation module.
 */
@Injectable()
export class CalculationEffects {
  /**
   * getAllCalculation$ is an effect that is used to get all calculations. Used for the
   * calculation list page.
   * @type {Observable<CalculationActions.getAllCalculation>}
   */
  getAllCalculation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CalculationActions.getAllCalculation, CalculationActions.loadCommonData),
      switchMap(() => {
        return this.api.getAllCalculation().pipe(
          map((response: CalculationDto[]) => {
            let dataToReturn: CalculationDto[];

            if (response === null || response === undefined) {
              dataToReturn = [];
            } else {
              dataToReturn = response;
            }

            // return CalculationActions.getAllCalculationSuccess({ data: dataToReturn });
            // this returns the data and the pagination data
            return CalculationActions.getAllCalculationSuccess({
              data: dataToReturn,
              paginationData: {
                totalCount: 100,
              },
            });
          }),
          catchError((err) => {
            return [
              // ErrorActions.ignoreNextGlobalHttpErrorHandling({active: {title: 'ups', description: 'error'}}),
              ErrorActions.ignoreNextGlobalHttpErrorHandling({}),
              CalculationActions.getAllCalculationFailure({ error: err }),
            ];
          }),
        );
      }),
    );
  });

  /**
   * getBusinessTypes$ is an effect that is used to get the business types. Used for the
   * calculation page.
   * @type {Observable<BusinessTypeActions.getBusinessTypes>}
   */
  getBusinessTypes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BusinessTypeActions.getBusinessTypes, BusinessTypesSelectedActions.updateSelected),
      concatLatestFrom(() => this.store.select(selectBusinessTypesSelected)),
      switchMap(([, state]) => {
        return this.api.getBusinessTypes(state).pipe(
          switchMap((response) => {
            return [
              BusinessTypeActions.getBusinessTypesSuccess({ businessTypes: response }),
              BusinessTypesSelectedActions.controlBusinessTypes({ businessTypes: response }),
            ];
          }),
          catchError((err) => [
            ErrorActions.ignoreNextGlobalHttpErrorHandling({}),
            BusinessTypeActions.getBusinessTypesFailure({ error: err }),
          ]),
        );
      }),
    );
  });

  /**
   * controlBusinessTypes$ is an effect that is used to control the business types. Used for the
   * calculation page.
   * @type {Observable<BusinessTypesSelectedActions.controlBusinessTypes>}
   */
  controlBusinessTypes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BusinessTypesSelectedActions.controlBusinessTypes),

      concatLatestFrom(() => [this.store.select(selectBusinessTypesAsArray), this.store.select(selectBusinessTypesSelected)]),

      switchMap(([action, value1, value2]) => {
        const newValue1: string[] = [];

        if (value1 && value1.length >= 1 && value1.length <= 4) {
          value1.forEach((value: BusinessTypeDto) => {
            if (value.businessFields.length === 1) {
              newValue1.push(Object.keys(value.businessFields[0])[0]);
            }
          });
        }

        const obj = { oldValue: value2, newValue: newValue1 };

        if (newValue1.length === 4) {
          return [BusinessTypesSelectedActions.updateSelectedOnlyModel(obj)];
        } else {
          const businessFields: BusinessFields[] = value1[value1.length - 1].businessFields;
          const newValue = [...value2, ...[Object.keys(businessFields[0])[0]]];

          if (businessFields.length === 1 && value1.length <= 4 && value2.length < 4) {
            const obj = { oldValue: value2, newValue: newValue };

            return [BusinessTypesSelectedActions.updateSelected(obj)];
          } else {
            return [];
          }
        }

        /*const businessFields: BusinessFields[] = value1[value1.length - 1].businessFields;
        const newValue = [...value2, ...[Object.keys(businessFields[0])[0]]];

        console.log('businessFields ', businessFields);
        console.log('newValue ', newValue);

        if (businessFields.length === 1 && value1.length <= 4 && value2.length < 4) {
          const obj = { oldValue: value2, newValue: newValue };

          console.log('calculation.effects. ', obj);

          return BusinessTypesSelectedActions.updateSelected(obj);
        } else {
          return BusinessTypesSelectedActions.doNothing();
        }*/
      }),
    );
  });

  getCalculationByVariantId$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CalculationActions.getCalculationByVariantId),
      switchMap((action) => {
        return this.api.getCalculationByVariantId(action.calculationID, action.variantID).pipe(
          map((response) => {
            console.warn(
              'calculation.effects.getCalculationByVariantId$ - TODO - return real data, when backend is implemented! ',
              response,
            );
            const responseDemo: CalculationDto = {
              id: 0,
              createdAt: 435200021,
              calculationVariantList: [
                {
                  id: 0,
                  variantName: 'Variant-Name 1',
                  objectName: 'Object-Name 1',
                  calculationResultInformation: {
                    grossRate: 0,
                    netRate: 0,
                    vatRate: 0,
                    duration: 0,
                    netResidualValue: 0,
                    residualValuePercentage: 0,
                    financialContribution: 0,
                    businessType: 'LVLN',
                  },
                  calculationVariantInformation: {
                    businessType: 'LVLN',
                    entireContractTerm: 12,
                    acquisitionCost: 10000,
                    specialPaymentFixed: 2000,
                    specialPaymentPercentage: 6,
                    fees: 500,
                    subsidiesFixed: 100,
                    subsidiesPercentage: 1.5,
                    refiInterestRate: 12.5,
                    marginFixed: 500,
                    marginPercentage: 500,
                    commission: 200,
                    commissionPercentage: 5,
                    bonus: 5,
                    condition: true,
                    residualValueFixed: 500,
                    residualValuePercentage: 50,
                    annualMileage: 100000,
                    kmStanding: 560000,
                    moreKM: 20,
                    lessKM: 5,
                    initialApproval: '2024-03-05T15:03:02.098Z',
                  },
                  offerId: 3,
                },
              ],
              addressId: 5,
            };

            return CalculationActions.getCalculationByVariantIdSuccess({ data: responseDemo /* response */ });
          }),
          catchError((err) => [
            ErrorActions.ignoreNextGlobalHttpErrorHandling({}),
            CalculationActions.getCalculationByVariantIdFailure({ error: err }),
          ]),
        );
      }),
    );
  });

  saveOrUpdateCalculation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CalculationActions.updateCalculation, CalculationActions.saveCalculation),
      switchMap((action) => {
        return this.api.saveOrUpdateCalculation(action).pipe(
          map((response) => {
            if (action.isUpdate) {
              return CalculationActions.updateCalculationSuccess();
            } else {
              return CalculationActions.saveCalculationSuccess();
            }
          }),
          catchError((err) => {
            const arrToReturn = [];

            arrToReturn.push(ErrorActions.ignoreNextGlobalHttpErrorHandling({}));

            if (action.isUpdate) {
              arrToReturn.push(CalculationActions.updateCalculationFailure({ error: err }));
            } else {
              arrToReturn.push(CalculationActions.saveCalculationFailure({ error: err }));
            }

            return arrToReturn;
          }),
        );
      }),
    );
  });

  deleteCalculationByVariantId$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CalculationActions.deleteCalculationByVariantId),
      switchMap((action) => {
        return this.api.deleteCalculationByVariantId(action.calculationVariantId).pipe(
          map((response) => {
            return CalculationActions.deleteCalculationByVariantIdSuccess({ calculationVariantId: action.calculationVariantId });
          }),
          catchError((err) => [
            ErrorActions.ignoreNextGlobalHttpErrorHandling({}),
            CalculationActions.deleteCalculationByVariantIdFailure({ error: err }),
          ]),
        );
      }),
    );
  });

  /**
   * Constructor for initializing the CalculationEffects instance
   * @param {Actions} actions$ - the actions
   * @param {CalculationApiService} api - the api
   * @param {Store} store - the store
   */
  constructor(
    private actions$: Actions,
    private api: CalculationApiService,
    private store: Store,
  ) {}
}
