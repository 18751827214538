import {getRouterSelectors, RouterReducerState} from '@ngrx/router-store';
import {createFeatureSelector, createSelector} from "@ngrx/store";
import {Params, UrlSegment} from "@angular/router";

 const selectRouter = createFeatureSelector<RouterReducerState>('router');

 const selectRouteNestedParams = createSelector(selectRouter, (router) => {
  let currentRoute = router?.state?.root;
  let params: Params = {};

  while (currentRoute?.firstChild) {
    currentRoute = currentRoute.firstChild;
    params = {
      ...params,
      ...currentRoute.params,
    };
  }

  return params;
});

 const selectRouteNestedParam = (param: string) =>
  createSelector(selectRouteNestedParams, (params) => params && params[param]);

 const selectCrrUrlSeq = createSelector( getRouterSelectors().selectCurrentRoute, crr => crr.url as UrlSegment[]);

 const selectPathByInd = (ind: number) =>
  createSelector(selectCrrUrlSeq, (seq: UrlSegment[]) => {
    if ( seq && seq [ind] ) return seq [ind].path;

    return undefined;
  });




export const routerSelectors = {
  selectRouter,
  selectRouteNestedParams,
  selectRouteNestedParam,
  selectCrrUrlSeq,
  selectPathByInd,
  ...getRouterSelectors(),
};
