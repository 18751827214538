import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Meta, TableDefault } from '../../types/meta';
import { filter, forkJoin, map, Observable, switchMap, tap } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectUserTableSettings } from '../../+state/user/user.selectors';
import { environment } from '@rundp/environment';

/**
 * Represents a service for retrieving metadata from an API endpoint.
 * @class
 * @public
 * @constructor
 */
@Injectable({
  providedIn: 'root',
})
export class MetaApiService {
  private readonly _http = inject(HttpClient);
  private readonly store = inject(Store);
  private tableSettings = this.store.select(selectUserTableSettings);

  /**
   * Retrieves the metadata from the specified API endpoint.
   *
   * @return {Observable<Meta>} An Observable that emits the metadata as a Meta object.

  public getMeta(): Observable<Meta> {
    return this._http.get<Meta>('/api/meta');
  }*/

  public getMeta() {
    return this.tableSettings.pipe(
      // wait for tableSettings from user obj.
      filter((tableSettings) => Array.isArray(tableSettings) && tableSettings.length > 0),
      // return only table names
      map((tableSettings) => tableSettings.map((tableSetting) => tableSetting.name)),
      // load all table meta
      switchMap((tableNames) => {
        const requestsObj: Record<string, Observable<Meta>> = {};

        // todo del result override to use real data
        tableNames = ['calculation', 'address'];

        // create Record of MetaTable Entities for tablenames - Entites will be loaded via http.get
        tableNames.forEach((value) => (requestsObj[value] = this._http.get<Meta>(environment.apiURL + '/' + value + '/meta')));

        // load all MetaTable Entities and map them to their corresponding names
        return forkJoin(requestsObj);
      }),
      // flat meta obj
      map((results: { [p: string]: Meta }) => {
        const meta: Meta = {
          tableDefaults: Object.values(results).reduce(
            (mergedTableDefaults: TableDefault[], currentTableMeta: Meta) => mergedTableDefaults.concat(currentTableMeta.tableDefaults),
            [],
          ),
        };

        return meta;
      }),
      tap((result) => {
        console.log(result);
      }),
    );
  }
}
