import { IsBIC, IsBoolean, IsIBAN, IsNumber, IsOptional, IsString } from 'class-validator';

export class BankConnectionDto {
  @IsNumber()
  @IsOptional()
  id: number;

  @IsString()
  @IsBIC()
  bic: string;

  @IsString()
  @IsIBAN()
  iban: string;

  @IsString()
  bankName: string;

  @IsNumber()
  addressId: number;

  @IsBoolean()
  @IsOptional()
  favorite?: boolean;

  constructor(id: number, bic: string, iban: string, bankName: string, addressId: number, favorite?: boolean) {
    this.id = id;
    this.bic = bic;
    this.iban = iban;
    this.bankName = bankName;
    this.addressId = addressId;
    this.favorite = favorite;
  }
}
