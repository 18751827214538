import { SearchEvent, SearchEventStore, SearchModes } from '../../types';
import { TypedAction } from '@ngrx/store/src/models';
import { initialPagination } from './initial-pagination';

/**
 * This method updates the applied filters on a given state
 * @param state The current state. Has to have a filter attribute.
 * @param action An action that includes the updated search data
 */
export function updateStateSearchData<State extends { filter: SearchEventStore }>(
  state: State,
  action: { search: SearchEvent } & TypedAction<string> & { type: string },
) {
  let filter;

  if (action.search.mode === SearchModes.STANDARD) {
    filter = {
      ...state.filter,
      mode: SearchModes.STANDARD,
      standard: action.search.values.standard,
    };
  } else {
    filter = {
      ...state.filter,
      mode: SearchModes.DETAILED,
      detailed: action.search.values,
    };
  }

  return {
    ...state,
    filter,
    pageData: initialPagination,
  };
}
