import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromMeta from './meta.reducers';
import { MetaState } from './meta.reducers';
import { TableDefault } from '../../types';
import { MetaDataProperty } from '../../types/meta-data-property.interface';

/**
 * Retrieves the meta state feature selector.
 *
 * @function selectMetaState
 * @returns {FeatureSelector<fromMeta.MetaState>} - The meta state feature selector.
 */
export const selectMetaState = createFeatureSelector<fromMeta.MetaState>(fromMeta.metaFeatureKey);

/**
 * Retrieves the table defaults from the meta state.
 *
 * @function selectTableDefaults
 *
 * @param {Function} selectMetaState - Selector function to get the meta state.
 *
 * @returns {TableDefault[]} - Selector function that returns the table defaults from the meta state.
 */
export const selectTableDefaults = createSelector(selectMetaState, (metaState) => metaState.tableDefaults);

/**
 * Selects the default table for a given index.
 *
 * @param {number} id - The index of the table.
 * @returns {TableDefault | undefined} - The default table for the given index, or undefined if not found.
 */
export const selectTableDefaultByInd = (id: number) =>
  createSelector<object, TableDefault[], TableDefault | undefined>(selectTableDefaults, (tableDefaults) => {
    const tableDefault: TableDefault | undefined = tableDefaults[id];

    return tableDefault;
  });

/**
 * Returns the default table that matches the given name.
 *
 * @param {string} name - The name of the table to search for.
 * @returns {TableDefault | undefined} - The default table that matches the given name, or undefined if not found.
 */
export const selectTableDefaultByName = (name: string) =>
  createSelector<object, TableDefault[], TableDefault | undefined>(selectTableDefaults, (tableDefaults) => {
    return tableDefaults?.find((value) => value.name === name);
  });

export const selectTableDefaultNames = createSelector(selectTableDefaults, (tableDefaults) => tableDefaults.map((value) => value.name));

export const selectTableDefaultColumnsOf = (name: string) =>
  createSelector(selectTableDefaultByName(name), (tableDefault) => tableDefault?.defaultCols);

export const selectTableColumnData = (tableName: MetaDataProperty, columnNames: string[]) =>
  createSelector(selectMetaState, (metaState: MetaState) => {
    console.log(metaState.tableDefaults, tableName, columnNames);
    const tableDefault = metaState.tableDefaults.find((tableDefault) => tableDefault.name === tableName);

    if (!tableDefault) {
      return [];
    }

    return tableDefault.availableCols.filter((col) => columnNames.includes(col.name));
  });
