import {createReducer, on} from '@ngrx/store';
import {errorInitState, ErrorState} from './errorInitState';
import {ErrorActions} from "./error.actions";

export const errorReducer = createReducer<ErrorState> (
  errorInitState,
  on ( ErrorActions.errorSet, ( state, { active } ): ErrorState => ({
    ...state,
    active
  })  ),
  on ( ErrorActions.errorReset, ( state ): ErrorState=> ({
    ...state,
    active: undefined
  }) ),
  on ( ErrorActions.ignoreNextGlobalHttpErrorHandling, ( state, { active } ): ErrorState => ({
    ...state, ignoreNextHttpErr: true,
    active
  }) ),
  on ( ErrorActions.restartNextGlobalHttpErrorHandling, ( state ): ErrorState => ({
    ...state,
    ignoreNextHttpErr: false
  }) )
);
