import {ErrorHandler, inject, Injector, makeEnvironmentProviders, Provider, Type} from '@angular/core';
import {DISPATCH_ERROR_HANDLER, DispatchErrorHandler} from './dispatch.error.handler';
import {SHOW_ERROR_HANDLER, ShowError} from './show.error.handler';
import {ERROR_FEEDBACK_MAIL, ERROR_IGNORE_STATUS_CODES, ERROR_IGNORE_URLS} from './error.token';
import {provideState, Store} from '@ngrx/store';
import {ErrorEffects, errorFeatureKey, errorReducer} from '../+state';
import {provideEffects} from '@ngrx/effects';
import {HTTP_MAX_RETRY_ATTEMPTS, HTTP_MAX_RETRY_SCALING_DURATION} from './server-error-interceptor';

/**
 * GlobalErrorHandler class implements the Angular ErrorHandler interface for handling and dispatching custom error actions.
 */
export class GlobalErrorHandler implements ErrorHandler {
  private readonly dispatchErrorHandler = inject(DISPATCH_ERROR_HANDLER);

  /**
   * Handles errors by dispatching custom error actions.
   * @param error The error to handle.
   */
  handleError(error: Error): void {
    this.dispatchErrorHandler.dispatchErrorActionFor(error);
  }
}

/**
 * Type for a ShowErrorHandler class constructor.

 type ShowHandlerClass<T extends ShowErrorHandler = ShowErrorHandler> = new (store: Store, email?: string) => T; */
// const store = inject(Store);

/**
 * Provides environment-specific error handling configuration.
 * @param config Configuration options for error handling.
 * @returns An array of providers for configuring error handling in the Angular application.
 */
export const provideReduxErrorHandling = (config?: {
  allowOverridingGlobalErrorHandler?: boolean;
  errorFeedbackMail?: string;
  ignoreHttpErrForThisUrls?: string[];
  ignoreHttpStatusCodes?: number[];
  httpMaxRetryAttempts?: number;
  httpRetryScaling?: number;
  showErrHandler?: Type<ShowError>;
  useDefaultShowErrorHandlerForMaterial?: boolean; // todo material error handler
  customErrDispatcherFactory?: (injector: Injector) => DispatchErrorHandler;
}) =>
  makeEnvironmentProviders([
    provideState(errorFeatureKey, errorReducer),
    provideEffects(ErrorEffects),
    ...(!config || config.allowOverridingGlobalErrorHandler
      ? ([{ provide: ErrorHandler, useClass: GlobalErrorHandler }] as Provider[])
      : []),

    ...(!config || config.errorFeedbackMail
      ? ([{ provide: ERROR_FEEDBACK_MAIL, useValue: config?.errorFeedbackMail }] as Provider[])
      : [{ provide: ERROR_FEEDBACK_MAIL, useValue: undefined }]),

    ...(config?.httpMaxRetryAttempts
      ? ([
          {
            provide: HTTP_MAX_RETRY_ATTEMPTS,
            useValue: config?.httpMaxRetryAttempts,
          },
        ] as Provider[])
      : []),

    ...(config?.httpRetryScaling
      ? ([
          {
            provide: HTTP_MAX_RETRY_SCALING_DURATION,
            useValue: config?.httpRetryScaling,
          },
        ] as Provider[])
      : []),

    ...(config?.ignoreHttpStatusCodes
      ? ([{ provide: ERROR_IGNORE_STATUS_CODES, useValue: config?.ignoreHttpStatusCodes }] as Provider[])
      : []),

    ...(config?.ignoreHttpErrForThisUrls
      ? ([{ provide: ERROR_IGNORE_URLS, useValue: config?.ignoreHttpErrForThisUrls }] as Provider[])
      : []),

    ...(config?.useDefaultShowErrorHandlerForMaterial
      ? ([
          /*
todo review material error dialog
{
provide: SHOW_ERROR_HANDLER, useClass: ShowErrorHandlerMaterial, deps: [
Store, ERROR_FEEDBACK_MAIL
]
}
*/
        ] as Provider[])
      : config?.showErrHandler
        ? ([
            {
              provide: SHOW_ERROR_HANDLER,
              useClass: config?.showErrHandler,
              deps: [Store, ERROR_FEEDBACK_MAIL],
            },
          ] as Provider[])
        : []),

    ...(config?.customErrDispatcherFactory
      ? ([
          {
            provide: DISPATCH_ERROR_HANDLER,
            useFactory: config?.customErrDispatcherFactory,
            deps: [Injector],
          },
        ] as Provider[])
      : []),
  ]);
