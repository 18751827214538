// Interface defining the structure of CalculationResultInformation
export interface CalculationResultInformation {
  grossRate: number;                  // Represents the gross rate in the calculation result
  netRate: number;                    // Represents the net rate in the calculation result
  vatRate: number;                    // Represents the VAT rate in the calculation result
  duration: number;                   // Represents the duration in the calculation result
  netResidualValue: number;           // Represents the net residual value in the calculation result
  residualValuePercentage: number;    // Represents the residual value percentage in the calculation result
  financialContribution: number;      // Represents the financial contribution in the calculation result
  businessType: string;               // Represents the business type in the calculation result
}

// Enum defining keys for the CalculationResultInformation interface
export enum CalculationResultEnum {
  GrossRate = 'grossRate',                    // Key for grossRate property
  NetRate = 'netRate',                        // Key for netRate property
  VatRate = 'vatRate',                        // Key for vatRate property
  Duration = 'duration',                      // Key for duration property
  NetResidualValue = 'netResidualValue',      // Key for netResidualValue property
  ResidualValuePercentage = 'residualValuePercentage', // Key for residualValuePercentage property
  FinancialContribution = 'financialContribution',     // Key for financialContribution property
  BusinessType = 'businessType',              // Key for businessType property
}

// Type representing a union of keys from the CalculationResultInformation interface
export type CalculationResultType =
  CalculationResultEnum.GrossRate |
  CalculationResultEnum.NetRate |
  CalculationResultEnum.VatRate |
  CalculationResultEnum.Duration |
  CalculationResultEnum.NetResidualValue |
  CalculationResultEnum.ResidualValuePercentage |
  CalculationResultEnum.FinancialContribution |
  CalculationResultEnum.BusinessType
  ;
