import { createActionGroup, props } from '@ngrx/store';
import { GenericTemplate, MetaDataProperty, TableTemplate, UserDto, UserType } from '../../types';

export const UserActions = createActionGroup({
  source: 'User',
  events: {
    'Load User': props<UserDto>,
    'Load User Success': props<{ data: UserType }>(),
    'Load User Failure': props<{ error: unknown }>(),

    'Delete Table Template': props<{ tableName: MetaDataProperty; templateId: number }>(),
    'Delete Table Template Success': props<{ tableName: MetaDataProperty; templateId: number }>(),
    'Update Selected Table Template': props<{ tableName: MetaDataProperty; template: GenericTemplate }>(),
    'Update Selected Table Template Success': props<{ tableName: MetaDataProperty; tableTemplate: TableTemplate }>(),
    'Update Selected And Create Table Template': props<{ tableName: MetaDataProperty; newTemplate: Omit<GenericTemplate, 'id'> }>(),
    'Update Selected And Create Table Template Success': props<{ tableName: MetaDataProperty; tableTemplate: TableTemplate }>(),
    'Update Selected Table Template Index': props<{ tableName: MetaDataProperty; templateId: number }>(),
    'Update Selected Table Template Index Success': props<{ tableName: MetaDataProperty; templateId: number }>(),
  },
});
