import { inject, InjectionToken } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpRequest, HttpResponse } from '@angular/common/http';
import { filter, Observable, of, retry, throwError, timer } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { DISPATCH_SERVER_ERROR_HANDLER } from './dispatch.server-error.handler';
import { ERROR_IGNORE_STATUS_CODES, ERROR_IGNORE_URLS } from './error.token';

/**
 * Intercepts HTTP requests and handles server errors, retries, and dispatching error actions.
 * @param req The HTTP request to intercept.
 * @param next The HTTP handler for handling the request.
 */
export function serverErrorInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
  const dispatchErrorHandler = inject(DISPATCH_SERVER_ERROR_HANDLER);
  const excludedURLs: string[] = inject(ERROR_IGNORE_URLS, { optional: true }) ?? [];
  const excludedHttpErrorCodes: number[] = inject(ERROR_IGNORE_STATUS_CODES, { optional: true }) ?? [];
  const maxRetryAttempts = inject(HTTP_MAX_RETRY_ATTEMPTS);
  const scalingDuration = inject(HTTP_MAX_RETRY_SCALING_DURATION);

  return next(req).pipe(
    filter((value) => value instanceof HttpResponse),
    mergeMap((value) => {
      const response = value as HttpResponse<unknown>;

      if (
        // !response.ok && (
        response.status >= 400 &&
        (excludedHttpErrorCodes.find((e) => e === response.status) || findStringInUrlList(excludedURLs, response.url))
      ) {
        return throwError(() => response);
      }

      return of(response);
    }),
    retry({
      count: maxRetryAttempts,
      delay: (error, retryCount) => {
        if (retryCount < maxRetryAttempts) {
          console.warn(`Attempt ${retryCount}: retrying in ${retryCount * scalingDuration}ms - ${error?.url ?? ''}`);

          return timer(retryCount * scalingDuration);
        } else {
          return throwError(() => error);
        }
      },
    }),
    catchError((error: HttpErrorResponse) => {
      if (notExcludedURL(excludedURLs, error.url) && notExcludedStatusCode(excludedHttpErrorCodes, error.status)) {
        // TODO: Status-Informationen aus dem Error korrekt auswerten und durchreichen
        dispatchErrorHandler.dispatchErrorActionFor(error);
      }

      return throwError(() => error);
    }),
  );
}

const findStringInUrlList = (urlList: string[], url: string | null) =>
  url && urlList.map((val) => val.replace(/^./, '')).filter((v) => url.indexOf(v) !== -1).length > 0;

/**
 * Injection token for the number of retry attempts for HTTP errors.
 */
export const HTTP_MAX_RETRY_ATTEMPTS = new InjectionToken<number>('number of retrys by htttp errors', {
  providedIn: 'root',
  factory: () => 0,
});

/**
 * Injection token for the scaling duration for the next retry.
 */
export const HTTP_MAX_RETRY_SCALING_DURATION = new InjectionToken<number>('scale duration for next retry by', {
  providedIn: 'root',
  factory: () => 500,
});

/**
 * Checks if the URL is not excluded from error handling.
 * @param excludedURLs Array of excluded URLs
 * @param url The URL to check.
 * @returns `true` if the URL is not excluded, `false` otherwise.
 */
function notExcludedURL(excludedURLs: string[], url: string | null): boolean {
  return !findStringInUrlList(excludedURLs, url);
}

/**
 * Checks if the HTTP status code is not excluded from error handling.
 * @param excludedHttpErrorCodes Array of excluded status codes
 * @param statusCode The HTTP status code to check.
 * @returns `true` if the status code is not excluded, `false` otherwise.
 */
function notExcludedStatusCode(excludedHttpErrorCodes: number[], statusCode: number): boolean {
  return excludedHttpErrorCodes.indexOf(statusCode) === -1;
}
