import { inject, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private _snackBar = inject(MatSnackBar);
  private translateService = inject(TranslateService);

  /**
   * Shows a new notification using a Material SnackBar
   * @param message Message to be displayed
   */
  createNotification(message: string) {
    this._snackBar.open(message, undefined, { duration: 2000 });
  }

  /**
   * Creates a new notification using a Material SnackBar after loading the given translation
   * @param messageKey Key of the translation to use
   */
  createNotificationWithTranslation(messageKey: string) {
    this.translateService
      .get(messageKey)
      .pipe(take(1))
      .subscribe((message) => {
        this.createNotification(message);
      });
  }
}
