import { createActionGroup, emptyProps, props } from '@ngrx/store';
import {
  AddressDto,
  AvailableCol,
  AvailableField,
  AvailableFilter,
  BankConnectionDto,
  ContactPersonDto,
  SearchEvent,
  TableRow,
} from '../../types';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { ServerTableResponse } from '../../interceptors';

export const AddressActions = createActionGroup({
  source: 'Address',
  events: {
    'Load Addresses': emptyProps(),
    'Load Addresses Success': props<ServerTableResponse<AddressDto[]>>(),
    'Load Addresses Failure': props<{ error: unknown }>(),
    'Load Address': props<{ id: string }>(),
    'Load Address Success': props<{ data: AddressDto }>(),
    'Load Address Failure': props<{ error: unknown }>(),
    'Create Address': props<{ address: Partial<AddressDto> }>(),
    'Create Address Success': props<{ data: AddressDto }>(),
    'Create Address Failure': props<{ error: unknown }>(),
    'Update Address': props<{ address: Partial<AddressDto> }>(),
    'Update Address Success': props<{ data: AddressDto }>(),
    'Update Address Failure': props<{ error: unknown }>(),
    'Delete Address': props<{ id: string }>(),
    'Delete Address Success': props<{ id: string | number }>(),
    'Delete Address Failure': props<{ error: unknown }>(),
    'Initialize Address Overview': emptyProps(),
    'Load Table Data Success': props<{ data: TableRow[] }>(),
    'Load Table Data Failure': props<{ error: unknown }>(),
    'Set Selected Address': props<{ id: string | number }>(),
    'Forward Addresses': props<{ ids: string[] }>(),
    'Forward Addresses Success': emptyProps(),
    'Forward Addresses Failure': props<{ error: unknown }>(),
    'Print Addresses': props<{ ids: string[] }>(),
    'Print Addresses Success': emptyProps(),
    'Print Addresses Failure': props<{ error: unknown }>(),
    'Load And Set Selected Address': props<{ id: string | number }>(),
    'Unset Selected Address': emptyProps(),
    'Load Table And Search Metadata': emptyProps(),
    'Update Pagination Data': props<{ page: PageEvent }>(),
    'Update Search Data': props<{ search: SearchEvent }>(),
    'Update Sort Data': props<{ sort: Sort }>(),
    'Update Quick Filters': props<{ selectedFilter: AvailableFilter; deleteFilter?: boolean }>(),
    'Set Table Cols': props<{ selectedItems: string[] }>(),
    'Set Table Cols and Metadata': props<{
      selectedItems: string[];
      tableMetadata: AvailableCol[];
      searchMetadata: AvailableField[];
    }>(),
    'Load Contact Persons Success': props<{ contactPersons: ContactPersonDto[] }>(),
    'Load Contact Persons Failure': props<{ error: unknown }>(),
    'Create Contact Person': props<{ contactPerson: Omit<ContactPersonDto, 'id'> }>(),
    'Create Contact Person Success': props<{ contactPerson: ContactPersonDto }>(),
    'Create Contact Person Failure': props<{ error: unknown }>(),
    'Update Contact Person': props<{ contactPerson: ContactPersonDto }>(),
    'Update Contact Person Success': props<{ contactPerson: ContactPersonDto }>(),
    'Update Contact Person Failure': props<{ error: unknown }>(),
    'Set Favorite Contact Person On Selected': props<{ contactPersonId: number }>(),
    'Delete Contact Person': props<{ contactPersonId: number }>(),
    'Delete Contact Person Success': props<{ contactPersonId: number }>(),
    'Delete Contact Person Failure': props<{ error: unknown }>(),
    'Load Bank Connections Success': props<{ bankConnections: BankConnectionDto[] }>(),
    'Load Bank Connections Failure': props<{ error: unknown }>(),
    'Create Bank Connection': props<{ bankConnection: Omit<BankConnectionDto, 'id'> }>(),
    'Create Bank Connection Success': props<{ bankConnection: BankConnectionDto }>(),
    'Create Bank Connection Failure': props<{ error: unknown }>(),
    'Update Bank Connection': props<{ bankConnection: BankConnectionDto }>(),
    'Update Bank Connection Success': props<{ bankConnection: BankConnectionDto }>(),
    'Update Bank Connection Failure': props<{ error: unknown }>(),
    'Set Favorite Bank Connection': props<{ bankConnectionId: number }>(),
    'Delete Bank Connection': props<{ bankConnectionId: number }>(),
    'Delete Bank Connection Success': props<{ bankConnectionId: number }>(),
    'Delete Bank Connection Failure': props<{ error: unknown }>(),
  },
});
