import {KeycloakService} from 'keycloak-angular';
import {inject} from '@angular/core';
import {CanActivateFn} from '@angular/router';
import {LoginService} from './login.service';
import {map, merge, skipWhile, Subject, tap} from 'rxjs';
import {toObservable} from '@angular/core/rxjs-interop';

/**
 * AuthGuard is a function used as a canActivate guard in Angular routes.
 * It checks if the user is authenticated using Keycloak and redirects them to the login page if not.
 *
 * @param route - The route being activated
 * @param state - The state of the router
 *
 * @returns An Observable that emits a boolean indicating if the route should be activated or not.
 *          If the user is authenticated, it emits true. If the user is not authenticated, it emits false and redirects to the login page.
 */
export const AuthGuard: CanActivateFn = (route, state) => {
  const keycloak = inject(KeycloakService);
  const loginService = inject(LoginService);
  const authKeycloakEvent$ = toObservable(loginService.authKeycloakEvent);

  const bypass = new Subject<boolean>();
  let timeout: ReturnType<typeof setTimeout> | undefined;

  // bypass needed to wait for running login processes
  return merge(
    authKeycloakEvent$.pipe(
      skipWhile((value, index) => {
        if (!value) {
          if (index === 0) {
            timeout = setTimeout(() => {
              bypass.next(false);
              bypass.complete();
            }, 2000);
          }

          return true;
        }

        return false;
      }),
    ),
    bypass,
  ).pipe(
    tap(() => {
      if (timeout) {
        clearTimeout(timeout);
      }

      bypass.complete();
    }),
    map((value) => {
      if (value && keycloak.isLoggedIn()) {
        return value;
      } else {
        keycloak.login({
          redirectUri: window.location.origin + state.url,
        });

        return false;
      }
    }),
  );
};
