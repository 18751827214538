import { InjectionToken } from '@angular/core';
import { GenericTemplate } from './user.dto';
import { TableSettingsAndSearchStoreCommands } from '../command-lists';
import { MetaDataProperty } from './meta-data-property.interface';

export type Item = {
  name: string;
  value: string;
};

export type Items = {
  activeItems: Item[];
  inactiveItems: Item[];
};

export type ColumnBox = {
  displayName?: string;
  columnName: string;
};

export const POPUP_ACTION_RESULT = {
  CREATE: 'create template',
  UPDATE: 'update template',
  APPLY: 'apply only',
} as const;

export type SaveAsTemplate = {
  result: typeof POPUP_ACTION_RESULT.CREATE | typeof POPUP_ACTION_RESULT.UPDATE;
  templateName: string;
};

export type SaveButtonError = 'no-selection' | 'no-name' | 'duplicate-name' | 'no-template-selected' | 'no-changes';

export type ApplyOnly = {
  result: typeof POPUP_ACTION_RESULT.APPLY;
};

export type NotDismissedEvent = {
  dismissed: false;
  selectedFields: ColumnBox[];
} & (SaveAsTemplate | ApplyOnly);

export type DialogDismissEvent = NotDismissedEvent | { dismissed: true };

export type TableConfigData = {
  selectableItems: Items;
  templates: GenericTemplate[];
  // title: string;
  titleLocalized: string;
  tableSettingsStoreCommands: TableSettingsAndSearchStoreCommands;
};

export const TABLE_CONFIG_DATA = new InjectionToken<TableConfigData>('TABLE CONFIG DATA', {
  factory: () => ({
    selectableItems: {
      activeItems: [],
      inactiveItems: [],
    },
    templates: [],
    // title: '',
    titleLocalized: '',
    tableSettingsStoreCommands: {
      tableName: MetaDataProperty.ADDRESS,
    },
  }),
});
