import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { filter, of } from 'rxjs';
import { catchError, concatMap, map, switchMap } from 'rxjs/operators';
import { AddressApiService } from '../../services';
import { AddressActions } from './address.actions';
import { Store } from '@ngrx/store';
import { selectAddress, selectAddressesQuery } from './address.selectors';
import { effectToSetDefaultsToTableStores } from '../helper/global-effects';
import { UserActions } from '../user';
import { MetaDataProperty } from '../../types';
import { BankConnectionService } from '../../services/bank-connection/bank-connection.service';
import { NotificationService } from '../../services/notification/notification.service';
import { ContactPersonService } from '../../services/contact-person/contact-person.service';

@Injectable()
export class AddressEffects {
  /**
   * Loads the global metadata for the table. Inspect the function to learn more
   */
  metaLoaded$ = createEffect(() => {
    return effectToSetDefaultsToTableStores(MetaDataProperty.ADDRESS, AddressActions.setTableColsAndMetadata);
  });

  /**
   * Loads all addresses with the query parameters currently set

  loadAddresses$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AddressActions.loadAddresses, AddressActions.initializeAddressOverview),
      concatLatestFrom(() => [this.store.select(selectAddressesQuery)]),
      switchMap(([, query]) =>
        this.api.getAddresses(query).pipe(
          map((response) => {
            return AddressActions.loadAddressesSuccess(response);
          }),
          catchError((err) =>
            // of(AddressActions.loadAddressesFailure({ error: err }))
            [
              // ErrorActions.ignoreNextGlobalHttpErrorHandling({active: {title: 'ups', description: 'error'}}),
              ErrorActions.ignoreNextGlobalHttpErrorHandling({}),
              AddressActions.loadAddressesFailure({ error: err }),
            ],
          ),
        ),
      ),
    );
  });*/

  /**
   * Loads a single address
   */
  loadAddress$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AddressActions.loadAddress, AddressActions.loadAndSetSelectedAddress),
      switchMap(({ id }) =>
        this.api.getAddress(id).pipe(
          map((response) => AddressActions.loadAddressSuccess({ data: response.data })),
          catchError((err) => of(AddressActions.loadAddressFailure({ error: err }))),
        ),
      ),
    );
  });

  /**
   * Gets all BankConnections from a single address
   */
  loadAddressBankConnections$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AddressActions.loadAddress, AddressActions.loadAndSetSelectedAddress),
      switchMap(({ id }) =>
        this.bankConnectionService.getBankConnectionsByAddressId(id).pipe(
          map((response) => AddressActions.loadBankConnectionsSuccess({ bankConnections: response })),
          catchError((err) => of(AddressActions.loadBankConnectionsFailure({ error: err }))),
        ),
      ),
    );
  });

  /**
   * Gets all ContactPersons from a single address
   */
  loadAddressContactPersons$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AddressActions.loadAddress, AddressActions.loadAndSetSelectedAddress),
      switchMap(({ id }) =>
        this.contactPersonService.getContactPersonsByAddressId(id).pipe(
          map((response) => AddressActions.loadContactPersonsSuccess({ contactPersons: response })),
          catchError((err) => of(AddressActions.loadContactPersonsFailure({ error: err }))),
        ),
      ),
    );
  });

  /**
   * Creates a new address from the data passed
   */
  createAddress$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AddressActions.createAddress),
      concatMap(({ address }) =>
        this.api.createAddress(address).pipe(
          map((response) => AddressActions.createAddressSuccess({ data: response })),
          catchError((err) => of(AddressActions.createAddressFailure({ error: err }))),
        ),
      ),
    );
  });

  /**
   * Creates a new BankConnection that will be connected to the currently selected address
   */
  createAddressBankConnection$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AddressActions.createBankConnection),
      switchMap((action) =>
        this.bankConnectionService.createBankConnection(action.bankConnection).pipe(
          map((response) =>
            AddressActions.createBankConnectionSuccess({
              bankConnection: { ...response.data, favorite: action.bankConnection.favorite },
            }),
          ),
          catchError((err) => of(AddressActions.createBankConnectionFailure({ error: err }))),
        ),
      ),
    );
  });

  /**
   * Creates a new ContactPerson that will be connected to the currently selected address
   */
  createAddressContactPerson$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AddressActions.createContactPerson),
      switchMap((action) =>
        this.contactPersonService.createContactPerson(action.contactPerson).pipe(
          map((response) =>
            AddressActions.createContactPersonSuccess({ contactPerson: { ...response.data, favorite: action.contactPerson.favorite } }),
          ),
          catchError((err) => of(AddressActions.createContactPersonFailure({ error: err }))),
        ),
      ),
    );
  });

  /**
   * Updates a BankConnection that is connected to the currently selected address
   */
  updateAddressBankConnection$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AddressActions.updateBankConnection),
      switchMap((action) =>
        this.bankConnectionService.updateBankConnection(action.bankConnection).pipe(
          map((response) =>
            AddressActions.updateBankConnectionSuccess({ bankConnection: { ...response, favorite: action.bankConnection.favorite } }),
          ),
          catchError((err) => of(AddressActions.updateBankConnectionFailure({ error: err }))),
        ),
      ),
    );
  });

  /**
   * Updates a ContactPerson that is connected to the currently selected address
   */
  updateAddressContactPerson$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AddressActions.updateContactPerson),
      switchMap((action) =>
        this.contactPersonService.updateContactPerson(action.contactPerson).pipe(
          map((response) =>
            AddressActions.updateContactPersonSuccess({
              contactPerson: {
                ...response.data,
                favorite: action.contactPerson.favorite,
              },
            }),
          ),
          catchError((err) => of(AddressActions.updateContactPersonFailure({ error: err }))),
        ),
      ),
    );
  });

  setFavoriteBankAccount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AddressActions.setFavoriteBankConnection),
      switchMap((action) =>
        this.bankConnectionService.setFavoriteBankConnection(action.bankConnectionId).pipe(
          map((response) => {
            this.notificationService.createNotificationWithTranslation('notification.favoriteChanged');

            return AddressActions.updateBankConnectionSuccess({ bankConnection: { ...response.data } });
          }),
          catchError((error) => {
            return of(AddressActions.updateAddressFailure({ error }));
          }),
        ),
      ),
    );
  });

  /**
   * Sets the favorite BankConnection for the currently selected address

  setFavoriteBankConnectionOnSelected$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        AddressActions.createBankConnectionSuccess,
        AddressActions.updateBankConnectionSuccess,
        AddressActions.setFavoriteBankConnectionOnSelected,
      ),
      // Execute this effect only when it's the setFavorite-Action or if the isFavorite flag is set
      filter((action) => action.type === AddressActions.setFavoriteBankConnectionOnSelected.type || !!action.bankConnection.favorite),
      concatLatestFrom(() => this.store.select(selectAddress)),
      switchMap(([data, address]) => {
        if (address) {
          // Get id of the favorite BankAccount
          let favoriteBankAccountId;

          switch (data.type) {
            case AddressActions.updateBankConnectionSuccess.type: {
              favoriteBankAccountId = data.bankConnection.id;
              break;
            }

            case AddressActions.setFavoriteBankConnectionOnSelected.type: {
              favoriteBankAccountId = data.bankConnectionId;
              break;
            }

            case AddressActions.createBankConnectionSuccess.type: {
              favoriteBankAccountId = data.bankConnection.id;
              break;
            }
          }

          return this.api.updateAddress({ ...address, favoriteBankAccountId }).pipe(
            map((data) => {
              this.notificationService.createNotificationWithTranslation('notification.favoriteChanged');

              return AddressActions.updateAddressSuccess({ data });
            }),
            catchError((err) => of(AddressActions.updateAddressFailure({ error: err }))),
          );
        } else {
          return of(AddressActions.updateAddressFailure({ error: 'No Address selected' }));
        }
      }),
    );
  });*/

  /**
   * Sets the favorite ContactPerson for the currently selected address
   */
  setFavoriteContactPersonOnSelected$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        AddressActions.createContactPersonSuccess,
        AddressActions.updateContactPersonSuccess,
        AddressActions.setFavoriteContactPersonOnSelected,
      ),
      // Execute this effect only when it's the setFavorite-Action or if the isFavorite flag is set
      filter((action) => action.type === AddressActions.setFavoriteContactPersonOnSelected.type || !!action.contactPerson.favorite),
      concatLatestFrom(() => this.store.select(selectAddress)),
      switchMap(([data, address]) => {
        if (address) {
          // Get id of the favorite ContactPerson
          let favoriteContactPersonId;

          switch (data.type) {
            case AddressActions.createContactPersonSuccess.type: {
              favoriteContactPersonId = data.contactPerson.id;
              break;
            }

            case AddressActions.updateContactPersonSuccess.type: {
              favoriteContactPersonId = data.contactPerson.id;
              break;
            }

            case AddressActions.setFavoriteContactPersonOnSelected.type: {
              favoriteContactPersonId = data.contactPersonId;
              break;
            }
          }

          return this.api.updateAddress({ ...address, favoriteContactPersonId }).pipe(
            map((data) => {
              this.notificationService.createNotificationWithTranslation('notification.favoriteChanged');

              return AddressActions.updateAddressSuccess({ data });
            }),
            catchError((err) => of(AddressActions.updateAddressFailure({ error: err }))),
          );
        } else {
          return of(AddressActions.updateAddressFailure({ error: 'No Address selected' }));
        }
      }),
    );
  });

  /**
   * Deletes the whole address
   */
  deleteAddress$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AddressActions.deleteAddress),
      concatMap(({ id }) =>
        this.api.deleteAddress(id).pipe(
          map(() => AddressActions.deleteAddressSuccess({ id })),
          catchError((err) => of(AddressActions.deleteAddressFailure({ error: err }))),
        ),
      ),
    );
  });

  /**
   * Delete a single ContactPerson
   */
  deleteContactPerson$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AddressActions.deleteContactPerson),
      switchMap((action) =>
        this.contactPersonService.deleteContactPerson(action.contactPersonId).pipe(
          map(() => AddressActions.deleteContactPersonSuccess({ contactPersonId: action.contactPersonId })),
          catchError((err) => of(AddressActions.deleteContactPersonFailure({ error: err }))),
        ),
      ),
    );
  });

  /**
   * Delete a single BankConnection
   */
  deleteBankConnection$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AddressActions.deleteBankConnection),
      switchMap((action) =>
        this.contactPersonService.deleteContactPerson(action.bankConnectionId).pipe(
          map(() => AddressActions.deleteBankConnectionSuccess({ bankConnectionId: action.bankConnectionId })),
          catchError((err) => of(AddressActions.deleteBankConnectionFailure({ error: err }))),
        ),
      ),
    );
  });

  /**
   * Update an address with new data
   */
  updateAddress$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AddressActions.updateAddress),
      concatMap(({ address }) =>
        this.api.updateAddress(address).pipe(
          map((response) => AddressActions.updateAddressSuccess({ data: response })),
          catchError((err) => of(AddressActions.updateAddressFailure({ error: err }))),
        ),
      ),
    );
  });

  /**
   * Get all addresses formatted for usage in the table component
   */
  loadTableData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AddressActions.loadAddresses, AddressActions.initializeAddressOverview),
      concatLatestFrom(() => [this.store.select(selectAddressesQuery)]),
      switchMap(([, params]) =>
        this.api.getTableData(params).pipe(
          map((response) => AddressActions.loadTableDataSuccess({ data: response.data.tableRows })),
          catchError((err) => of(AddressActions.loadTableDataFailure({ error: err }))),
        ),
      ),
    );
  });

  /**
   * Updates the currently loaded addresses when a parameter changes, e.g. the currently selected page
   */
  updateAddresses$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        AddressActions.updatePaginationData,
        AddressActions.updateSearchData,
        AddressActions.updateSortData,
        AddressActions.updateQuickFilters,
      ),
      map(() => AddressActions.loadAddresses()),
    );
  });

  /**
   * Updates the table columns that the user has selected to be shown
   */
  updateSelectedTableCols = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.updateSelectedTableTemplateSuccess, UserActions.updateSelectedAndCreateTableTemplateSuccess),
      filter((action) => action.tableName === MetaDataProperty.ADDRESS),
      map((action) => AddressActions.setTableCols({ selectedItems: action.tableTemplate.selectedCols })),
    );
  });

  constructor(
    private actions$: Actions,
    private api: AddressApiService,
    private bankConnectionService: BankConnectionService,
    private contactPersonService: ContactPersonService,
    private notificationService: NotificationService,
    private store: Store,
  ) {}
}
