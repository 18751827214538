import { HttpEvent, HttpEventType, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { inject } from '@angular/core';
import { NotificationService } from '../services/notification/notification.service';

/**
 * Enum representing the possible response status types.
 *
 * @enum {string}
 */
export enum ResponseStatusType {
  /**
   * Represents the success status returned by an operation.
   *
   * @type {string}
   */
  SUCCESS = 'SUCCESS',
  /**
   * Represents the constant variable FAILURE.
   *
   * @constant {string}
   * @description This variable is used to represent the failure state or condition.
   */
  FAILURE = 'FAILURE',
  /**
   * Represents the information string.
   *
   * @constant {String} INFO
   * @description Used to store the information string.
   */
  INFO = 'INFO',
}

/**
 * Represents the response received from the server.
 * @template T - The type of the data in the response.
 */
export type ServerResponse<T> = {
  status?: {
    type: ResponseStatusType;
    title?: string;
    message?: string;
  };
  data: T;
};

/**
 * Represents a server message response.
 * @template T - The type of the server response data.
 */
export type ServerMessageResponse<T> = {
  status?: {
    type: ResponseStatusType.FAILURE | ResponseStatusType.INFO;
    title: string;
    message: string;
  };
} & ServerResponse<T>;

/**
 * Represents a server table response object.
 *
 * @template T - The type of the data in the server table response.
 */
export type ServerTableResponse<T> = {
  data: {
    totalCount: number;
    searchRequestDTO: {
      size: number;
      offset: number;
      sort: string;
      direction: string;
      query: string | null;
      page: number;
    };
    tableRows: T;
  };
  pagination: {
    page: number;
    limit: number;
    numberOfElements: number;
    totalCount: number;
    totalPages: number;
    hasNext: boolean;
    first: boolean;
    last: boolean;
  };
} & ServerResponse<T>;

/**
 * Intercepts the response of an HTTP request and creates a notification if the response body contains a status with a title.
 *
 * @param req - The HTTP request to intercept.
 * @param next - The next HTTP handler function.
 * @returns An Observable of the HTTP event.
 */
export function statusInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
  const notificationService = inject(NotificationService);

  return next(req).pipe(
    map((event) => {
      if (event.type === HttpEventType.Response) {
        const body = event.body as ServerResponse<unknown> | ServerTableResponse<unknown> | ServerMessageResponse<unknown>;

        // If body contains a status element, the transmitted message will create a notification
        if (body?.status && body?.status?.title) {
          notificationService.createNotification(body.status.title);
        }

        // The event is then passed on without the status element
        delete body.status;

        return event.clone({
          body,
        });
      }

      return event;
    }),
  );
}
