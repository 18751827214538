import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { UserApiService } from '../../services/user/user-api.service';
import { ErrorActions } from '../error';
import { UserActions } from './user.actions';
import { TableTemplate, UserDto, UserType } from '../../types';

@Injectable()
export class UserEffects {
  /**
   * Loads the userdata from the API
   */
  loadUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.loadUser),
      switchMap(() =>
        this.api.getUser().pipe(
          map((response: UserDto) => {
            const newData: UserType = {
              user: response,
            };

            // return UserActionsGroup.loadUserSuccess({ data: newData });
            return UserActions.loadUserSuccess({ data: newData });
          }),
          catchError((err) => {
            return [
              // ErrorActions.ignoreNextGlobalHttpErrorHandling({active: {title: 'ups', description: 'error'}}),
              ErrorActions.ignoreNextGlobalHttpErrorHandling({}),
              UserActions.loadUserFailure({ error: err }),
            ];
          }),
        ),
      ),
    );
  });

  /**
   * Deletes a table template of the current user
   */
  deleteTableTemplate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.deleteTableTemplate),
      switchMap((action) =>
        this.api.deleteTableTemplate(action.tableName, action.templateId).pipe(
          map(() => {
            return UserActions.deleteTableTemplateSuccess({ tableName: action.tableName, templateId: action.templateId });
          }),
          catchError(() =>
            // of(AddressActions.loadAddressesFailure({ error: err }))
            [
              // TODO: Error-Handling wieder aktivieren
              // ErrorActions.ignoreNextGlobalHttpErrorHandling({active: {title: 'ups', description: 'error'}}),
              ErrorActions.ignoreNextGlobalHttpErrorHandling({}),
            ],
          ),
        ),
      ),
    );
  });

  /**
   * Updates a table template of the current user
   */
  updateSelectedTableTemplate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.updateSelectedTableTemplate),
      switchMap((action) => {
        const mappedTemplate: TableTemplate = { ...action.template, selectedCols: action.template.selectedItems };

        return this.api.updateSelectedTableTemplate(action.tableName, mappedTemplate).pipe(
          map((tableTemplate) => {
            return UserActions.updateSelectedTableTemplateSuccess({
              tableName: action.tableName,
              tableTemplate,
            });
          }),
          catchError(() =>
            // of(AddressActions.loadAddressesFailure({ error: err }))
            [
              // TODO: Error-Handling wieder aktivieren
              // ErrorActions.ignoreNextGlobalHttpErrorHandling({active: {title: 'ups', description: 'error'}}),
              ErrorActions.ignoreNextGlobalHttpErrorHandling({}),
            ],
          ),
        );
      }),
    );
  });

  /**
   *
   */
  updateSelectedAndCreateTableTemplate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.updateSelectedAndCreateTableTemplate),
      switchMap((action) =>
        this.api
          .updateSelectedAndCreateTableTemplate(action.tableName, {
            name: action.newTemplate.name,
            selectedCols: action.newTemplate.selectedItems,
          })
          .pipe(
            map((response) => {
              return UserActions.updateSelectedAndCreateTableTemplateSuccess({
                tableName: action.tableName,
                tableTemplate: response,
              });
            }),
            catchError(() =>
              // of(AddressActions.loadAddressesFailure({ error: err }))
              [
                // TODO: Error-Handling wieder aktivieren
                // ErrorActions.ignoreNextGlobalHttpErrorHandling({active: {title: 'ups', description: 'error'}}),
                ErrorActions.ignoreNextGlobalHttpErrorHandling({}),
              ],
            ),
          ),
      ),
    );
  });

  updateSelectedTableTemplateIndex$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.updateSelectedTableTemplateIndex),
      switchMap((action) =>
        this.api.updateSelectedTableTemplateIndex(action.templateId).pipe(
          map((response) => {
            return UserActions.updateSelectedTableTemplateIndexSuccess({
              tableName: action.tableName,
              templateId: response,
            });
          }),
          catchError(() =>
            // of(AddressActions.loadAddressesFailure({ error: err }))
            [
              // TODO: Error-Handling wieder aktivieren
              // ErrorActions.ignoreNextGlobalHttpErrorHandling({active: {title: 'ups', description: 'error'}}),
              ErrorActions.ignoreNextGlobalHttpErrorHandling({}),
            ],
          ),
        ),
      ),
    );
  });

  constructor(
    private actions$: Actions,
    private api: UserApiService,
  ) {}
}
