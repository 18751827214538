export const environment = {
  production: false,
  apiURL: 'https://sales2-dev.rpecom.de',
  apiEndpoints: {
    address: '/address',
    addressMetaData: '/address/meta',
    addressTableData: '/address',
    bankAccount: '/bankaccount',
    contactPerson: '/contact',
  },
  keycloakURL: 'https://keycloak.dev.nettrek.group/',
  calculationApi: {
    getAllCalculation: '/api/calculation/all',
    getBusinessTypes: '/api/calculation-business-types/types',
    getCalculationByVariantId: '/api/calculation',
    getTableMetadata: '/api/table-metadata/calculation',
    getSearchMetadata: '/api/search-metadata/calculation',
  },
};
