import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ContactPersonDto } from '../../types/contact-person/contact-person.dto';
import { environment } from '@rundp/environment';
import { Observable } from 'rxjs';
import { ServerResponse } from '../../../index';

/**
 * Service for managing contact persons.
 */
@Injectable({
  providedIn: 'root',
})
export class ContactPersonService {
  private _http = inject(HttpClient);

  /**
   * Retrieves the contact person with the given ID from the API.
   *
   * @param {number} id - The ID of the contact person.
   * @return {Observable<ContactPersonDto>} - A promise that resolves with the contact person data retrieved from the API.
   */
  public getContactPerson(id: number): Observable<ContactPersonDto> {
    return this._http.get<ContactPersonDto>(environment.apiURL + environment.apiEndpoints.contactPerson + '/' + id);
  }

  /**
   * Retrieves a list of contact persons by addressId.
   *
   * @param {number | string} addressId - The ID of the address.
   * @return {Observable<ContactPersonDto[]>} - The list of contact persons as an Observable.
   */
  public getContactPersonsByAddressId(addressId: number | string): Observable<ContactPersonDto[]> {
    return this._http.get<ContactPersonDto[]>(environment.apiURL + environment.apiEndpoints.contactPerson + '/all/' + addressId);
  }

  /**
   * Creates a new contact person.
   *
   * @param {Omit<ContactPersonDto, 'id'>} contactPerson - The contact person object to be created.
   * @return {Observable<ServerResponse<ContactPersonDto>>} - An observable that emits the created contact person object.
   */
  public createContactPerson(contactPerson: Omit<ContactPersonDto, 'id'>): Observable<ServerResponse<ContactPersonDto>> {
    return this._http.post<ServerResponse<ContactPersonDto>>(environment.apiURL + environment.apiEndpoints.contactPerson, contactPerson);
  }

  /**
   * Updates a contact person.
   *
   * @param {ContactPersonDto} contactPerson - The contact person object to be updated.
   * @return {Observable<ServerResponse<ContactPersonDto>>} - An Observable resolving to the updated contact person object.
   */
  public updateContactPerson(contactPerson: ContactPersonDto): Observable<ServerResponse<ContactPersonDto>> {
    return this._http.put<ServerResponse<ContactPersonDto>>(environment.apiURL + environment.apiEndpoints.contactPerson, contactPerson);
  }

  /**
   * Deletes a contact person by ID.
   *
   * @param {number} id - The ID of the contact person to be deleted.
   * @returns {Observable<ContactPersonDto>} - An Observable that emits the deleted contact person.
   */
  public deleteContactPerson(id: number): Observable<ContactPersonDto> {
    return this._http.delete<ContactPersonDto>(environment.apiURL + environment.apiEndpoints.contactPerson + '/' + id);
  }
}
