import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as fromCalculation from './calculation.reducers';
import { CalculationDto } from '../../types';

/**
 * Retrieves the calculation state from the calculation feature using createFeatureSelector.
 *
 * @type {import('@ngrx/store').MemoizedSelector<object, fromCalculation.CalculationState>}
 * @param {fromCalculation.calculationFeatureKey} the key of the calculation feature state
 * @returns {MemoizedSelector<object, fromCalculation.CalculationState>} the memoized selector for the calculation state
 */
export const selectCalculationState = createFeatureSelector<fromCalculation.CalculationState>(fromCalculation.calculationFeatureKey);

// #############################################
/**
 * Returns the calculation state from the Redux store.
 *
 * @param {Function} selectCalculationState - A function to select the calculation state from the Redux store.
 * @param {Function} calcState - The calculation state retrieved from the Redux store.
 * @returns {fromCalculation.CalculationState} - The calculation state from the Redux store.
 */
export const selectAllCalculation = createSelector(selectCalculationState, (calcState) => calcState);

// #############################################

/**
 * Returns a selector function that retrieves a calculation item by its index.
 *
 * @param {number} id - The index of the calculation item to retrieve.
 *
 * @returns {CalculationDto | undefined} The selector function.
 */
export const selectCalculationItemByInd = (id: number) => createSelector(selectAllCalculation, (value) => {
  return value.calculations[id];
});

/**
 * Selects a calculation item by its ID.
 *
 * @param {number} id - The ID of the calculation item to select.
 * @returns {CalculationDto | undefined} - A selector function that returns the calculation item with the specified ID.
 */
export const selectCalculationItemById = (id: number) =>
  createSelector(selectAllCalculation, (value) => {
    return value.calculations.find((value) => value.id === id);
  });

/**
 * Selects a calculation item by a given address ID.
 *
 * @param {number} addressId - The address ID of the calculation item to select.
 * @returns {CalculationDto | undefined} - The selector function that returns the calculation item.
 */
export const selectCalculationItemByAddressId = (addressId: number) =>
  createSelector(selectAllCalculation, (value) => {
    return value.calculations.find((value) => value.addressId === addressId);
  });

export const selectSelectedVariantDetails = createSelector(selectCalculationState, (calcState) => calcState.selectedVariantDetails);

export const selectCalculationVariantListFromTableData = createSelector(selectCalculationState, (calcState) => {
  return calcState.tableData?.map((value) => {

    console.log('calculation.selectors.{{{{ a ', (value.data as unknown as CalculationDto).calculationVariantList);
    console.log('calculation.selectors.{{{{ b ', ((value.data as unknown as CalculationDto).calculationVariantList as any ).html);

    return (value.data as unknown as CalculationDto).calculationVariantList;
  });
});

// #############################################

/**
 * Returns a memoized selector function for getting the new calculation value from the calculation state object.
 *
 * @param {Function} selectCalculationState - A selector function for getting the calculation state object.
 *
 * @returns {NewCalculation} - A memoized selector function that returns the new calculation value.
 */
export const selectNewCalculation = createSelector(selectCalculationState, (calcState) => calcState.newCalculation);

/**
 * Retrieves an array of business types from the calculation state.
 *
 * @param {Function} selectCalculationState - The selector function to get the calculation state.
 * @param {Object} calcState - The calculation state object.
 * @returns {BusinessTypeDto[]} - An array of business types from the calculation state.
 */
//
export const selectBusinessTypesAsArray = createSelector(selectCalculationState, (calcState) => calcState.newCalculation.businessTypes);

export const selectBusinessTypesArea = createSelector(selectCalculationState, (calcState) => calcState.newCalculation.businessTypes[0]);
/**
 * Retrieves a specific business type by index from the calculation state.
 *
 * @param {number} index - The index of the business type to retrieve.
 * @returns {BusinessTypeDto|undefined} - A selector function that selects the specified business type from the calculation state.
 * select the defined business types in the array by index 1
 * @returns the business type by index 1
 */
//
export const selectBusinessTypesByIndex = (index: number) => createSelector(selectCalculationState, (calcState) => calcState.newCalculation.businessTypes[index]);

export const selectBusinessTypesAmortization = createSelector(selectCalculationState, (calcState) => calcState.newCalculation.businessTypes[1]);
/**
 * Returns a selector that retrieves the selectBusinessTypesArea from the calculation state.
 *
 * @param {Function} selectCalculationState - The selector function for retrieving the calculation state.
 *
 * @returns {BusinessTypeDto|undefined} The selector that retrieves the selectBusinessTypesArea.
 * select the defined business types in the array by index 2
 * @returns the business type by index 2
 */
//
export const selectBusinessTypesProgression = createSelector(selectCalculationState, (calcState) => calcState.newCalculation.businessTypes[2]);

/**
 * This variable is a selector function that returns the selected business types from the calculation state.
 *
 * @function selectBusinessTypesSelected
 * @type {Function}
 * @return {string[]} The selected business types.
 * select the defined business types in the array by index 3
 * @returns the business type by index 3
 */
export const selectBusinessTypesSelected = createSelector(selectCalculationState, (calcState) => calcState.newCalculation.selectedBusinessTypes);
export const selectBusinessTypesDate = createSelector(selectCalculationState, (calcState) => calcState.newCalculation.businessTypes[3]);
