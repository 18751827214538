import {HttpEvent, HttpHandlerFn, HttpRequest} from '@angular/common/http';
import {inject} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';

/**
 * Adds an interceptor to set the 'Accept-Language' header based on the current language from TranslateService.
 *
 * @param req - The original HTTP request.
 * @param next - The next HTTP handler function.
 *
 * @returns An observable of the HTTP event after modifying the request with the 'Accept-Language' header.
 */
export function addAcceptLanguageInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
  // const localeID = inject(LOCALE_ID);
  const translateService = inject(TranslateService);

  const modifiedReq = req.clone({
    headers: req.headers.set('Accept-Language', translateService.currentLang ?? translateService.getDefaultLang()),
  });

  return next(modifiedReq);
}
