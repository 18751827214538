/**
 * Enum representing the available search field types.
 *
 * @enum {string}
 */
export enum SearchFieldTypeEnum {
  string = 'string',
  number = 'number',
  boolean = 'boolean',
  date = 'date',
  dropdown = 'dropdown',
}

/**
 * Represents the type of a search field.
 *
 * @typedef {String} SearchFieldType - The type of a search field.
 * @property {String} string - Represents a search field of type string.
 * @property {String} number - Represents a search field of type number.
 * @property {String} boolean - Represents a search field of type boolean.
 * @property {String} date - Represents a search field of type date.
 * @property {String} dropdown - Represents a search field of type dropdown.
 */
type SearchFieldType =
  | SearchFieldTypeEnum.string
  | SearchFieldTypeEnum.number
  | SearchFieldTypeEnum.boolean
  | SearchFieldTypeEnum.date
  | SearchFieldTypeEnum.dropdown;

/**
 * Represents a base search field configuration.
 * @typedef {Object} SearchFieldBase
 * @property {string} label - The label of the search field.
 * @property {string} name - The name of the search field.
 * @property {string} [placeholder] - The placeholder text for the search field.
 * @property {RegExp|string} [regex] - Regular expression or string pattern used for validation.
 * @property {string} [regexError] - Error message to be displayed if the value entered does not match the regex pattern.
 * @property {SearchFieldType} type - The type of the search field.
 * @property {SelectValue[]} [values] - Array of selectable values for the search field if it is of type 'SELECT'.
 */
export type SearchFieldBase = {
  label: string;
  name: string;
  placeholder?: string;
  regex?: RegExp | string;
  regexError?: string;
  type: SearchFieldType;
  values?: SelectValue[];
};

/**
 * Represents a non-select search field.
 * @typedef {SearchFieldBase} NonSelectSearchField
 * @property {SearchFieldType} type - The type of the search field, excluding dropdown.
 */
type NonSelectSearchField = SearchFieldBase & {
  type: Exclude<SearchFieldType, SearchFieldTypeEnum.dropdown>;
};

/**
 * Represents a select search field.
 * @typedef {Object} SelectSearchField
 * @property {SearchFieldTypeEnum} type - The type of the search field. Must be 'dropdown'.
 * @property {SelectValue[]} values - The list of select values for the dropdown.
 */
type SelectSearchField = SearchFieldBase & {
  type: SearchFieldTypeEnum.dropdown;
  values: SelectValue[];
};

/**
 * Represents a selectable value.
 *
 * @typedef {Object} SelectValue
 * @property {string} label - The label of the value.
 * @property {string|number|boolean} value - The value itself, which can be a string, number, or boolean.
 */
type SelectValue = {
  label: string;
  value: string | number | boolean;
};

/**
 * Represents a search field.
 * This can be either a non-select search field or a select search field.
 */
export type SearchField = NonSelectSearchField | SelectSearchField;

/**
 * The SearchFieldKey represents the key for the search field.
 *
 * @typedef {string} SearchFieldKey
 * @alias SearchFieldKey
 * @description The key is a string value that represents the name of the search field.
 */
export type SearchFieldKey = SearchField['name'];

/**
 * Represents a collection of control values.
 */
export type ControlValues = Record<string, string | number | boolean | null>;
