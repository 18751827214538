import { createFeature, createReducer, on } from '@ngrx/store';
import {
  AddressDto,
  AvailableCol,
  AvailableField,
  AvailableFilter,
  BankConnectionDto,
  ContactPersonDto,
  SearchEventStore,
  SearchModes,
  TableRow,
} from '../../types';
import { deleteReducer, pageFailureReducer, upsertReducer } from '../common/reducer';
import { AddressActions } from './address.actions';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { initialPagination } from '../common/initial-pagination';
import { updateStateSearchData } from '../common/update-state-search-data.function';

export const addressFeatureKey = 'address';

/**
 * AddressState interface represents the state of the address  in the application.
 * It contains properties for addresses, current page ids, total count, page data,
 * filter, quick filters, sort, table metadata, search metadata, table data, selected address id,
 * contact persons, bank connections, and selected table columns.
 *
 * @interface AddressState
 */
export interface AddressState {
  addresses: Record<string | number, AddressDto>;
  currentPageIds: string[] | null;
  totalCount: number;
  pageData: PageEvent;
  filter: SearchEventStore;
  quickFilters: AvailableFilter[];
  sort: Sort;
  tableMetadata: AvailableCol[] | null;
  searchMetadata: AvailableField[] | null;
  tableData: TableRow[] | null;
  selectedAddressId: string | number | null;
  contactPersons: ContactPersonDto[];
  bankConnections: BankConnectionDto[];

  selectedTableCols: string[];
}

export const initialState: AddressState = {
  addresses: {},
  currentPageIds: null,
  totalCount: 0,
  pageData: initialPagination,
  filter: {
    mode: SearchModes.STANDARD,
    standard: null,
    detailed: {},
  },
  quickFilters: [],
  sort: {
    active: 'id',
    direction: 'asc',
  },
  tableMetadata: null,
  searchMetadata: null,
  tableData: null,
  selectedAddressId: null,
  contactPersons: [],
  bankConnections: [],

  selectedTableCols: [],
};

export const reducer = createReducer(
  initialState,
  //on(AddressActions.loadAddressesSuccess, (state, action) => pageReducer(state, action, 'addresses', 'currentPageIds')),
  on(AddressActions.loadAddressesFailure, (state) => pageFailureReducer(state, 'currentPageIds')),
  on(AddressActions.loadAddressSuccess, (state, action) => upsertReducer(state, action, 'addresses')),
  on(AddressActions.loadAddressFailure, (state) => pageFailureReducer(state, 'currentPageIds')),
  on(AddressActions.createAddressSuccess, AddressActions.updateAddressSuccess, (state, action) =>
    upsertReducer(state, action, 'addresses'),
  ),
  on(AddressActions.deleteAddressSuccess, (state, action) => deleteReducer(state, action, 'addresses')),
  on(AddressActions.loadTableDataSuccess, (state, { data }): AddressState => ({ ...state, tableData: data })),
  on(AddressActions.loadTableDataFailure, (state): AddressState => ({ ...state, tableData: null })),
  on(
    AddressActions.setSelectedAddress,
    AddressActions.loadAndSetSelectedAddress,
    (state, { id }): AddressState => ({ ...state, selectedAddressId: id }),
  ),
  on(AddressActions.unsetSelectedAddress, (state): AddressState => ({ ...state, selectedAddressId: null })),
  on(AddressActions.updatePaginationData, (state, action): AddressState => ({ ...state, pageData: action.page })),
  on(AddressActions.updateSearchData, (state, action): AddressState => {
    return updateStateSearchData(state, action);
  }),
  on(
    AddressActions.updateSortData,
    (state, action): AddressState => ({
      ...state,
      sort: action.sort,
      pageData: initialPagination,
    }),
  ),
  on(AddressActions.updateQuickFilters, (state, action): AddressState => {
    if (action.deleteFilter) {
      return {
        ...state,
        quickFilters: state.quickFilters.filter((filter) => filter.id !== action.selectedFilter.id),
      };
    }

    return {
      ...state,
      quickFilters: [...state.quickFilters, action.selectedFilter],
    };
  }),
  on(
    AddressActions.setTableCols,
    (state, action): AddressState => ({
      ...state,
      selectedTableCols: action.selectedItems,
    }),
  ),
  on(
    AddressActions.setTableColsAndMetadata,
    (state, action): AddressState => ({
      ...state,
      selectedTableCols: action.selectedItems,
      tableMetadata: action.tableMetadata,
      searchMetadata: action.searchMetadata,
    }),
  ),
  on(
    AddressActions.loadContactPersonsSuccess,
    (state, action): AddressState => ({
      ...state,
      contactPersons: action.contactPersons,
    }),
  ),
  on(AddressActions.createContactPersonSuccess, (state, action): AddressState => {
    const addresses = { ...state.addresses };

    // TODO: Remove the array update once a proper API is ready
    if (state.selectedAddressId) {
      let currentAddress = addresses[state.selectedAddressId];

      currentAddress = {
        ...currentAddress,
        contactPersonIds: [...currentAddress.contactPersonIds, action.contactPerson.id],
      };
      addresses[state.selectedAddressId] = currentAddress;
    }

    return { ...state, addresses, contactPersons: [...state.contactPersons, action.contactPerson] };
  }),
  on(AddressActions.updateContactPersonSuccess, (state, action): AddressState => {
    const contactPersons = [...state.contactPersons];
    const contactPersonIndex = contactPersons.findIndex((contactPerson) => contactPerson.id === action.contactPerson.id);

    if (contactPersonIndex === -1) {
      return state;
    }

    contactPersons[contactPersonIndex] = action.contactPerson;

    return { ...state, contactPersons };
  }),
  on(AddressActions.deleteContactPersonSuccess, (state, action): AddressState => {
    return {
      ...state,
      contactPersons: state.contactPersons.filter((contactPerson) => contactPerson.id !== action.contactPersonId),
    };
  }),
  on(
    AddressActions.loadBankConnectionsSuccess,
    (state, action): AddressState => ({
      ...state,
      bankConnections: action.bankConnections,
    }),
  ),
  on(AddressActions.createBankConnectionSuccess, (state, action): AddressState => {
    const addresses = { ...state.addresses };

    // TODO: Remove the array update once a proper API is ready
    let updatedAddress = addresses[action.bankConnection.addressId];

    if (updatedAddress) {
      updatedAddress = {
        ...updatedAddress,
        bankAccountIds: [...updatedAddress.bankAccountIds, action.bankConnection.id],
      };

      if (action.bankConnection.favorite) {
        updatedAddress.favoriteBankAccountId = action.bankConnection.id;
      }

      addresses[updatedAddress.id] = updatedAddress;
    }

    return { ...state, addresses, bankConnections: [...state.bankConnections, action.bankConnection] };
  }),
  on(AddressActions.updateBankConnectionSuccess, (state, action): AddressState => {
    const bankConnections = [...state.bankConnections];
    const bankConnectionIndex = bankConnections.findIndex((bankConnection) => bankConnection.id === action.bankConnection.id);
    const addresses = { ...state.addresses };

    // TODO: Hier könnten wir die Bankverbindung neu einfügen, anstatt einfach einen Return zu machen?
    if (bankConnectionIndex === -1) {
      return state;
    }

    if (action.bankConnection.favorite) {
      let updatedAddress = addresses[action.bankConnection.addressId];

      if (updatedAddress) {
        updatedAddress = {
          ...updatedAddress,
          favoriteBankAccountId: action.bankConnection.id,
        };

        addresses[updatedAddress.id] = updatedAddress;
      }
    }

    bankConnections[bankConnectionIndex] = action.bankConnection;

    return { ...state, addresses, bankConnections };
  }),
  on(AddressActions.deleteBankConnectionSuccess, (state, action): AddressState => {
    return {
      ...state,
      bankConnections: state.bankConnections.filter((bankConnection) => bankConnection.id !== action.bankConnectionId),
    };
  }),
);

export const addressFeature = createFeature({
  name: addressFeatureKey,
  reducer,
});
