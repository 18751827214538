import { createActionGroup, props } from '@ngrx/store';
import {Meta, TableDefault} from '../../types/meta';

export const MetaActionsGroup = createActionGroup({
  source: 'Meta',
  events: {
    'Load Meta': props<Meta>,
    'Load Meta Success': props<{ data: Meta }>(),
    'Load Meta Failure': props<{ error: unknown }>(),
    /**
     * Only for Unit Testing to use
     */
    'Add Meta Template': props<{ tableDefault: TableDefault }>()

  }
});
