/**
 * Enum representing the available search modes.
 *
 * @enum {string}
 * @readonly
 */
export enum SearchModes {
  STANDARD = 'standard',
  DETAILED = 'detailed',
}

/**
 * Represents a standard search event.
 *
 * @typedef {Object} StandardSearchEvent
 * @property {SearchModes.STANDARD} mode - The mode of the search event.
 * @property {Object} values - The values associated with the search event.
 * @property {string|null} values.standard - The standard to be searched, or null if not specified.
 */
export type StandardSearchEvent = {
  mode: SearchModes.STANDARD;
  values: {
    standard: string | null;
  };
};

/**
 * Determines if the given event is a standard search event.
 *
 * @param {SearchEvent | SearchEventStore} event - The event to check.
 * @returns {boolean} Returns true if the event is a standard search event, otherwise false.
 */
export const isStandardSearchEvent = (event: SearchEvent | SearchEventStore) => event.mode === SearchModes.STANDARD;

/**
 * Represents a search event with detailed search mode and corresponding values.
 *
 * @typedef {object} DetailSearchEvent
 *
 * @property {SearchModes.DETAILED} mode - The search mode. (enum: SearchModes.DETAILED)
 * @property {Record<string, string | number | boolean | null>} values - The search values.
 */
export type DetailSearchEvent = {
  mode: SearchModes.DETAILED;
  values: Record<string, string | number | boolean | null>;
};

/**
 * Represents a search event.
 * @typedef {(StandardSearchEvent | DetailSearchEvent)} SearchEvent
 */
export type SearchEvent = StandardSearchEvent | DetailSearchEvent;

export type SearchEventStore = {
  mode: SearchModes;
  standard: string | null;
  detailed: Record<string, string | number | boolean | null>;
};
