import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CalculationDto } from '../../types/calculation/calculation.dto';
import { BusinessTypeDto } from '../../types/calculation/business-type.dto';
import { environment } from '@rundp/environment';
import { CalculationVariant, SearchField, TableMetadata } from '../../types';

/**
 * A class that provides API services related to calculations.
 */
@Injectable({
  providedIn: 'root'
})
export class CalculationApiService {
  private readonly _http = inject(HttpClient);

  /**
   * Get all calculations
   * @returns {Observable<CalculationDto[]>} - all calculations
   */
  getAllCalculation() {
    return this._http.get<CalculationDto[]>(environment.calculationApi.getAllCalculation);
    // customer backend does not provide a result
    // return this._http.get<CalculationDto[]>(environment.apiURL + `/calculation/all`);
  }

  /**
   * Get calculation by id
   * @returns {Observable<CalculationDto>} - calculation
   * @param currentSelectedValues - @type string[]
   *
   */
  getBusinessTypes(currentSelectedValues: string[]) {
    return this._http.post<BusinessTypeDto[]>(environment.calculationApi.getBusinessTypes + '?params=' + currentSelectedValues,
      {
        params: null,
        data: currentSelectedValues,
        timeout: 0,
        responseType: 'json'
      }
    );
  }

  getCalculationByVariantId(calculationID: string, variantID: string) {
    let url = environment.apiURL + '/calculation';

    // url += '/' + calculationID;
    url += '/' + variantID;

    return this._http.get<CalculationDto>(url);
  }

  saveOrUpdateCalculation(action: { data: CalculationVariant, isUpdate: boolean }) {
    return this._http.post<BusinessTypeDto[]>(environment.calculationApi.getCalculationByVariantId,
      {
        params: null,
        data: action.data,
        timeout: 0,
        responseType: 'json'
      }
    );
  }

  deleteCalculationVariantById(variantID: string) {
    let url = environment.apiURL + '/calculation';

    url += '/' + variantID;

    return this._http.delete(url);
  }

  getTableMetadata() {
    return this._http.get<TableMetadata[]>(environment.calculationApi.getTableMetadata);
  }

  deleteCalculationByVariantId(calculationVariantId: number) {
    return this._http.delete<void>(environment.calculationApi.getCalculationByVariantId +`/${calculationVariantId}`);
  }

  getSearchMetadata() {
    return this._http.get<SearchField[]>(environment.calculationApi.getSearchMetadata);
  }
}


