
// muss leer sein um zu schauen ob das laden erfolgreich war

import {ErrorCodeDetails} from "../../error/error-codes";
export type ErrType = 'exception' | 'http';
export interface ErrorState {
  active?: ErrorCodeDetails,
  ignoreNextHttpErr?: boolean
  errType?: ErrType
}

export const errorInitState: ErrorState = { active: undefined };


