import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MetaApiService } from '../../services/meta/meta-api.service';
import { ErrorActions } from '../error/error.actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { MetaActionsGroup } from './meta.actions';

@Injectable()
export class MetaEffects {
  constructor(
    private actions$: Actions,
    private api: MetaApiService,
  ) {}

  /**
   * Loads the Metadata from the API
   */
  loadMeta$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MetaActionsGroup.loadMeta),
      switchMap(() =>
        this.api.getMeta().pipe(
          map((response) => {
            return MetaActionsGroup.loadMetaSuccess({ data: response });
          }),
          catchError((err) => [
            // ErrorActions.ignoreNextGlobalHttpErrorHandling({active: {title: 'ups', description: 'error'}}),
            ErrorActions.ignoreNextGlobalHttpErrorHandling({}),
            MetaActionsGroup.loadMetaFailure({ error: err }),
          ]),
        ),
      ),
    );
  });
}
