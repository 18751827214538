import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromAddress from './address.reducer';
import { StateToQuery } from '../common/state-to-query.function';
import { AddressDto } from '../../types';
import { routerSelectors } from '../router';

/**
 * Retrieves the address state from the Redux store based on the feature key.
 *
 * @function selectAddressState
 * @returns {import('@ngrx/store').MemoizedSelector<object, fromAddress.AddressState>}
 *            The memoized selector for retrieving the address state.
 * @description This function is used to create a feature selector for the address state from the Redux store.
 *              The feature selector is created using the createFeatureSelector function from the @ngrx/store library.
 *              It takes the feature key, 'fromAddress.addressFeatureKey', as an argument to select the corresponding
 *              state from the store.
 */
export const selectAddressState = createFeatureSelector<fromAddress.AddressState>(fromAddress.addressFeatureKey);

/**
 * Returns a selector function that retrieves all addresses from the address state.
 *
 * @param {Function} selectAddressState - The selector function to retrieve the address state.
 * @returns {Record<string | number, AddressDto>} - The selector function that returns all addresses from the address state.
 */
export const selectAllAddresses = createSelector(selectAddressState, (state) => state.addresses);

/**
 * Returns the total count of addresses from the address state.
 *
 * @param {Function} selectAddressState - The selector function that returns the address state.
 * @returns {number} - The total count of addresses.
 */
export const selectAddressesTotalCount = createSelector(selectAddressState, (state) => state.totalCount);

/**
 * Returns a selector function that retrieves the current page of addresses from the address state.
 *
 * @param {Function} selectAddressState - A selector function that selects the address state from the Redux store.
 *
 * @returns {string[]} A selector function that returns an array of addresses for the current page.
 */
export const selectAddressesPage = createSelector(
  selectAddressState,
  (state) => state.currentPageIds?.map((id) => state.addresses[id]) ?? [],
);

/**
 * Returns an object containing pagination data for the address feature.
 *
 * @param {Object} state - The address state object.
 * @param {Object} pageData - The page data object from the address feature.
 * @returns {{pageIndex: number, pageSize: number, totalCount: number}} The pagination data object.
 */
export const selectPaginationData = createSelector(selectAddressState, fromAddress.addressFeature.selectPageData, (state, pageData) => ({
  pageIndex: pageData?.pageIndex ?? 0,
  pageSize: pageData?.pageSize ?? 10,
  totalCount: state.totalCount,
}));

/**
 * A data selector for retrieving the sorting information from the address state.
 *
 * @param {Function} selectAddressState - The selector function for getting the address state.
 * @param {Function} getSort - The function for getting the sorting information from the address state.
 * @returns {Sort} - The sorting information from the address state.
 */
export const selectSortData = createSelector(selectAddressState, (state) => state.sort);

/**
 * A selector function that returns the filter data from the
 * given address state.
 *
 * @param {Function} selectAddressState - A selector function that returns
 *                                      the address state from the Redux store.
 * @returns {SearchEventStore} - A memoized selector function that returns the filter data.
 */
export const selectSearchData = createSelector(selectAddressState, (state) => state.filter);

/**
 * Retrieves the quick filters from the address state.
 *
 * @param {Function} selectAddressState - Selector function to retrieve the address state.
 * @returns {AvailableFilter[]} - Selector function to retrieve the quick filters from the address state.
 */
export const selectQuickFilters = createSelector(selectAddressState, (state) => state.quickFilters);

/**
 * Retrieves the selected table columns from the address state using the createSelector function.
 *
 * @param {Function} selectAddressState - The selector function to retrieve the address state.
 * @param {Object} state - The address state object.
 * @returns {string[]} - An array of selected table columns.
 */
export const selectSelectedTableCols = createSelector(selectAddressState, (state) => state.selectedTableCols);

/**
 * Returns a selector function that selects the address based on the address selected ID.
 *
 * @param {Function} selectAddresses - The selector function that selects the list of addresses.
 * @param {Function} selectSelectedAddressId - The selector function that selects the ID of the selected address.
 * @returns {Record<string | number, AddressDto>|null} - The selector function that selects the address based on the given ID.
 */
export const selectAddress = createSelector(
  fromAddress.addressFeature.selectAddresses,
  fromAddress.addressFeature.selectSelectedAddressId,
  (addresses, id) => (id ? addresses[id] : null),
);

/**
 * Returns a selector function that selects the address based on given address ID.
 *
 * @param {string | number} addressID The address ID to search for
 */
export const selectAddressById = (addressID: string | number) =>
  createSelector(fromAddress.addressFeature.selectAddresses, (addresses) => addresses[addressID]);

/**
 * Returns a selector function that retrieves the addresses query from the address state.
 *
 * @param {Function} selectAddressState - A selector function to retrieve the address state.
 * @returns {HttpParams} - A selector function that retrieves the addresses query.
 */
export const selectAddressesQuery = createSelector(selectAddressState, (state) => StateToQuery<AddressDto>(state));

/**
 * Returns a selector function that selects bank connections that are associated with a specific address.
 *
 * @param {Function} selectAddressState - The selector function for selecting the address state.
 * @param {Function} selectAddress - The selector function for selecting the address.
 * @returns {BankConnectionDto[]} - The selector function that selects bank connections based on the given address.
 */
export const selectBankConnections = createSelector(selectAddressState, selectAddress, (state, address) => {
  return state.bankConnections.filter((bankConnection) => address?.bankAccountIds.includes(bankConnection.id));
});

/**
 * Returns an array of contact persons associated for the selected address.
 *
 * @param {Function} selectAddressState - The selector function to select the address state.
 * @param {Function} selectAddress - The selector function to select the address.
 * @param {Function} stateAddressCallback - The callback function to filter and retrieve the contact persons.
 * @returns {ContactPersonDto[]} An array of contact persons associated with the address.
 */
export const selectContactPersons = createSelector(selectAddressState, selectAddress, (state, address) => {
  return state.contactPersons.filter((contactPerson) => address?.contactPersonIds.includes(contactPerson.id));
});

/**
 * Selects the favorite bank connection based on the address state and address.
 *
 * @param {Function} selectAddressState - The selector function to select the address state.
 * @param {Function} selectAddress - The selector function to select the address.
 * @returns {BankConnectionDto|undefined} A function that returns the favorite bank connection.
 *
 */
export const selectFavoriteBankConnection = createSelector(selectAddressState, selectAddress, (state, address) => {
  return state.bankConnections.find((bankConnection) => bankConnection.id === address?.favoriteBankAccountId);
});

/**
 * Returns the favorite contact person based on the address's favoriteContactPersonId.
 *
 * @param {Function} selectAddressState - The selector function that returns the address state.
 * @param {Function} selectAddress - The selector function that returns the address.
 * @returns {ContactPersonDto|undefined} - The favorite contact person object, if found. Otherwise, undefined.
 */
export const selectFavoriteContactPerson = createSelector(selectAddressState, selectAddress, (state, address) => {
  return state.contactPersons.find((contactPerson) => contactPerson.id === address?.favoriteContactPersonId);
});

/**
 * Creates a selector that returns a contact person based on the URL Param contactID.
 * @function selectContactPersonByURLId
 *
 * @param {function} selectAddressState - A selector function that returns the address state.
 * @param {function} selectAddress - A selector function that returns the selected address.
 * @param {function} selectRouteNestedParam - A selector function that returns the nested parameter from the route.
 * @returns {ContactPersonDto|undefined} - The contact person found based on the URL ID, or null if not found.
 */
export const selectContactPersonByURLId = createSelector(
  selectAddressState,
  selectAddress,
  routerSelectors.selectRouteNestedParam('contactID'),
  (state, selectedAddress, contactId) => {
    let contactPerson = state.contactPersons.find((contactPerson) => contactPerson.id === parseInt(contactId, 10));

    if (contactPerson) {
      contactPerson = { ...contactPerson, favorite: selectedAddress?.favoriteContactPersonId === contactPerson.id };
    }

    return contactPerson;
  },
);
