import { IsNumber } from 'class-validator';

// Defining a type for BusinessFields as a Record with string keys and string values
export type BusinessFields = Record<string, string>;

// Class representing the BusinessTypeDto
export class BusinessTypeDto {

  // Applying IsNumber decorator to ensure the position is a number
  @IsNumber()
  position: number;

  // An array of business fields, where each field is a key-value pair
  businessFields: BusinessFields[];

  // Constructor for initializing the BusinessTypeDto instance
  constructor(position: number, businessFields: BusinessFields[]) {
    // Setting the position property
    this.position = position;

    // Setting the businessFields property
    this.businessFields = businessFields;
  }

}
