import { PageEvent } from '@angular/material/paginator';
import { PaginatedRequestParamsFactory } from '../../helpers/classes/paginated-request-params-factory';
import { HttpParams } from '@angular/common/http';
import { Sort as MatSort } from '@angular/material/sort';
import { AvailableFilter, Filter, isStandardSearchEvent, SearchEventStore, Sort } from '../../types';

/**
 * Takes the current state and builds a search query from it, like applying filters and the current page.
 * @param state The current state to use. Has to have pageData, filter, sort and quickFilters as attributes.
 */
export function StateToQuery<DTO>(state: {
  pageData: PageEvent;
  filter: SearchEventStore;
  sort: MatSort;
  quickFilters: AvailableFilter[];
}): HttpParams {
  const filter: Filter<DTO> = {};
  const sort: Sort<DTO> = {
    sortBy: state.sort.active as keyof DTO,
    sortDirection: state.sort.direction === '' ? 'asc' : state.sort.direction,
  };

  if (isStandardSearchEvent(state.filter)) {
    filter.all = state.filter.standard ?? undefined;
  } else {
    for (const [key, value] of Object.entries(state.filter.detailed)) {
      // @ts-expect-error Assignment of the value renders an error when the DTO is not explicitly set
      filter[key as keyof DTO] = value;
    }
  }

  return new PaginatedRequestParamsFactory<DTO>()
    .addFilter(filter)
    .addQuickFilters(state.quickFilters)
    .addSort(sort)
    .addPagination(state.pageData)
    .build();
}
