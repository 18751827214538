import {Links} from './meta';

/**
 * This DTO contains information on which menu items are displayed in the navigation bar. A menu item has a unique name and a router link (href). Submenu items can also be defined for each menu item!
 */
export interface NavbarItem {
  displayName: string;
  href?: string; // optional, when sublinks are defined
  // max deep 3
  subLinks?: NavbarItem[];
  disabled?: boolean;
}

export const isDropdownLink = (link: NavbarItem) => !!(link as NavbarItem).subLinks;

/**
 * This DTO has information on a template set by the user. It has a name, an optional ID because it is defined by the system
 * and a list (selectedFields) of selected columns. The list contains AvailableCol.tableColumnName values that are defined in the meta!
 */
interface Template {
  id: number;
  name: string;
}

/**
 * Represents a generic template.
 * @interface
 * @extends Template
 */
export interface GenericTemplate extends Template {
  selectedItems: string[];
}

/**
 * Represents a table template that extends a generic template.
 * @interface TableTemplate
 * @extends Template
 */
export interface TableTemplate extends Template {
  selectedCols: string[];
}

/**
 * Represents a search template that extends the base Template interface.
 */
export interface SearchTemplate extends Template {
  selectedFields: string[];
}

/**
 * Settings and templates for tables are saved in this DTO.
 * The table name that is entered must be unique and correspond to the TableDefault.name!
 * The selectedTemplate field contains the ID (Template.id) of the template that the user has selected for this table.
 * -1 must be used, if no selection ist done and TableDefault.defaultCols should be shown!
 * All templates are stored in the templates list
 * pageCount ist optional and defines how many rows should be shown. If undefined 10 will be used as default
 *
 * @interface
 * @name TableSetting
 * @property {string} name - The name of the table setting.
 * @property {number} [pageCount] - The number of pages in the table setting. Default value is 10.
 * @property {number} selectedTableTemplateID - The ID of the selected table template.
 * @property {number} selectedSearchTemplateID - The ID of the selected search template.
 * @property {TableTemplate[]} tableTemplates - An array of table templates.
 * @property {SearchTemplate[]} searchTemplates - An array of search templates.
 */
export interface TableSetting {
  name: string;
  // default = 10
  pageCount?: number;
  // -1 if default should be used
  selectedTableTemplateID: number;
  selectedSearchTemplateID: number;
  tableTemplates: TableTemplate[];
  searchTemplates: SearchTemplate[];
}

/**
 * Represents a generic table setting.
 * @interface
 * @extends TableSetting
 */
export interface GenericTableSetting extends TableSetting {
  templates: GenericTemplate[];
  selectedTemplateID: number;
}

/**
 * Represents a Footer component with build information, version number, and links.
 * Footer information such as the status of the application and the links to be displayed in the footer
 * @interface
 */
export interface Footer {
  build: string;
  version: string;
  links: Links[];
}

/**
 * Represents a user data transfer object.
 * General user information such as the KeyCloak ID, name and role. The saved templates for the respective tables and the navbar information are also transported here.
 *
 * @interface UserDto
 */
export interface UserDto {
  id: string;
  firstname: string;
  lastname: string;
  role: string;
  navbarItems: NavbarItem[];
  tableSettings: TableSetting[];
  footer: Footer;
}

/**
 * Represents a user type.
 * @typedef {Object} UserType
 * @property {UserDto} user - The user associated with this user type.
 */
export type UserType = {
  user: UserDto;
};

/**
 * Represents user information.
 * @typedef {Object} UserInfo
 * @property {string} firstName - The first name of the user.
 * @property {string} lastName - The last name of the user.
 * @property {string} [imageUrl] - The URL of the user's profile image. (Optional)
 */
export type UserInfo = {
  firstName: string;
  lastName: string;
  imageUrl?: string;
};
