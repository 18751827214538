import { inject, Injectable } from '@angular/core';
import { MetaDataProperty, TableTemplate } from '../../types';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { environment } from '@rundp/environment';

@Injectable({
  providedIn: 'root',
})
export class UserApiService {
  readonly _http = inject(HttpClient);

  public getUser() {
    // return this._http.get<UserDto>(`/api/user`);
    return of({
      id: 'keycloakID',
      firstname: 'Max',
      lastname: 'Mustermann',
      role: 'Role',
      navbarItems: [
        {
          displayName: 'Adressen',
          href: '/addresses',
        },
        {
          displayName: 'Verträge',
          href: '/contract',
        },
        {
          displayName: 'Asset-Management',
          href: '/assetManagement',
        },
        {
          displayName: 'Kalkulation',
          href: '/calculation',
        },
        {
          displayName: 'Verwaltung',
          href: '/administration',
        },
        {
          displayName: 'Demo',
          subLinks: [
            {
              displayName: 'subLinkDemo1',
              subLinks: [
                {
                  displayName: 'subSubLinkDemo1',
                  href: '/subSubLinkDemo1NotWorking',
                },
              ],
            },
          ],
        },
      ],
      tableSettings: [
        {
          name: 'address',
          selectedTableTemplateID: -1,
          selectedSearchTemplateID: 1,
          pageCount: 100,
          tableTemplates: [
            {
              id: 1,
              name: 'Vorlage 1',
              selectedCols: ['name1'],
            },
            {
              id: 2222,
              name: 'Vorlage 2',
              selectedCols: ['name1', 'streetWithNo', 'contactPerson'],
            },
          ],
          searchTemplates: [
            {
              id: 1,
              name: 'Vorlage 1',
              selectedFields: ['unique ID as String: name', 'addressNumber'],
            },
            {
              id: 2,
              name: 'Vorlage 2',
              selectedFields: ['unique ID as String: name', 'addressNumber'],
            },
          ],
        },
        {
          name: 'bank',
          selectedTableTemplateID: 1,
          tableTemplates: [
            {
              id: 1,
              name: 'Vorlage 1',
              selectedCols: ['unique ID as String: name', 'bankIBAN'],
            },
          ],
          selectedSearchTemplateID: 1,
          searchTemplates: [
            {
              id: 1,
              name: 'Vorlage 1',
              selectedFields: ['unique ID as String: name', 'addressNumber'],
            },
            {
              id: 2,
              name: 'Vorlage 2',
              selectedFields: ['unique ID as String: name', 'addressNumber'],
            },
          ],
        },
      ],
      footer: {
        build: '915',
        version: '1.0',
        links: [
          {
            displayName: 'News',
            href: '/news',
          },
          {
            displayName: 'Hilfe',
            href: '/help',
          },
          {
            displayName: 'Kontakt',
            href: '/contact',
          },
          {
            displayName: 'Impressum',
            href: '/imprint',
          },
          {
            displayName: 'Datenschutz',
            href: '/data-privacy',
          },
        ],
      },
    });
  }

  public deleteTableTemplate(tableName: MetaDataProperty, templateId: number) {
    return this._http.delete(environment.apiURL + '/' + tableName + '/table/columns/template/' + templateId);
  }

  public updateSelectedTableTemplate(tableName: MetaDataProperty, template: TableTemplate) {
    return this._http.put<TableTemplate>(environment.apiURL + '/' + tableName + '/table/columns/template', template);
  }

  public updateSelectedAndCreateTableTemplate(tableName: MetaDataProperty, template: Omit<TableTemplate, 'id'>) {
    return this._http.post<TableTemplate>(environment.apiURL + '/' + tableName + '/table/columns/template', template);
  }

  /**
   *
   * @param templateId ID of the newly selected template
   */
  public updateSelectedTableTemplateIndex(templateId: number) {
    // TODO: Implement once API is ready
    return of(templateId);
  }
}
