import { IsNumber, IsOptional } from 'class-validator';
import { CalculationVariant } from './calculation-variant';

// Class representing the CalculationDto
export class CalculationDto {

  // Applying IsNumber decorator to ensure id is a number
  @IsNumber()
  id: number;

  // Applying IsNumber decorator to ensure addressId is a number
  @IsNumber()
  addressId: number;

  // Applying IsOptional decorator to allow undefined values
  @IsOptional()
  calculationVariantList: CalculationVariant[];

  // Applying IsNumber decorator and IsOptional decorator to allow undefined values
  @IsNumber()
  @IsOptional()
  createdAt: number;

  // Constructor for initializing the CalculationDto instance
  constructor(
    id: number,
    createdAt: number,
    calculationVariantList: CalculationVariant[],
    addressId: number
  ) {
    // Setting the id property
    this.id = id;

    // Setting the createdAt property
    this.createdAt = createdAt;

    // Setting the calculationVariantList property
    this.calculationVariantList = calculationVariantList;

    // Setting the addressId property
    this.addressId = addressId;
  }
}
