import { HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { finalize, Observable } from 'rxjs';
import { LoaderCounterStore } from '../+state/loading';

export function loadingInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
  const store = inject(LoaderCounterStore);

  store.increment();

  return next(req).pipe(
    finalize(() => {
      store.decrement();
    })
  );
}
