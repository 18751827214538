import { produce } from 'immer';

/**
 * Updates the page data and upserts the items map with the new data
 * @param state State to update
 * @param action Action with data to update
 * @param collectionKey  Key of the map of items in the state
 * @param pageKey Key of the page data in the state
 * @returns Updated state
 *
 * @example
 * const reducer = createReducer(
 *  initialState,
 *  on(actions.pageSuccess, (state, action) => pageReducer(state, action, 'items', 'page')),
 * );
 */
export const pageReducer = <State extends { totalCount: number }, Dto extends { id: string | number }>(
  state: State,
  action: { data: Dto[]; paginationData: { totalCount: number } },
  collectionKey: keyof State,
  pageKey: keyof State,
) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  produce<State>(state, (draft: any) => {
    const ids = action.data.map((item) => item.id);

    draft[pageKey] = ids;

    const existing = draft[collectionKey] || [];

    action.data.forEach((item) => {
      existing[item.id] = item;
    });

    draft[collectionKey] = existing;
    draft.totalCount = action.paginationData.totalCount;
  });

/**
 * Sets the page data to null
 * @param state State to update
 * @param pageKey Key of the page data in the state
 * @returns Updated state
 *
 * @example
 * const reducer = createReducer(
 *  initialState,
 *  on(actions.pageFailure, (state, action) => pageFailureReducer(state, 'items')),
 * );
 */
export const pageFailureReducer = <State>(state: State, pageKey: keyof State) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  produce<State>(state, (draft: any) => {
    draft[pageKey] = null;
  });

/**
 * Upserts a single item into a map of items
 * @param state State to update
 * @param action Action with data to update
 * @param key Key of the map of items to update
 * @returns Updated state
 *
 * @example
 * const reducer = createReducer(
 *  initialState,
 *  on(actions.upsert, (state, action) => upsertReducer(state, action, 'items')),
 * );
 */
export const upsertReducer = <State, Dto extends { id: string | number }>(state: State, action: { data: Dto }, key: keyof State) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  produce<State>(state, (draft: any) => {
    draft[key][action.data.id] = action.data;
  });

export const deleteReducer = <State>(state: State, action: { id: string | number }, key: keyof State) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  produce<State>(state, (draft: any) => {
    draft[key][action.id] = undefined;
  });
