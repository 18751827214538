import { createFeature, createReducer, on } from '@ngrx/store';
import { MetaActionsGroup } from './meta.actions';
import { TableDefault } from '../../types/meta';

export const metaFeatureKey = 'meta';


export interface MetaState {
  tableDefaults: TableDefault[];
}

export const initialMeta: MetaState = {
  tableDefaults: []
};

export const metaReducer = createReducer(
  initialMeta,
  on(MetaActionsGroup.loadMeta, (state): MetaState => ({ ...state })),

  on(MetaActionsGroup.loadMetaSuccess, (state, {data}): MetaState =>
    ({
      ...state,
      tableDefaults: data.tableDefaults
    })),

  on(MetaActionsGroup.loadMetaFailure, (state): MetaState => ({ ...state })),

  on(MetaActionsGroup.addMetaTemplate, (state, {tableDefault}): MetaState =>
    ({
      ...state,
      tableDefaults: [...state.tableDefaults, tableDefault]
    })),
);

export const metaFeature = createFeature({
  name: metaFeatureKey,
  reducer: metaReducer
});

